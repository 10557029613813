define('excelixis-client/helpers/avatar-switcher', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.avatarSwitcher = avatarSwitcher;
    function avatarSwitcher(params /*, hash*/) {
        if (params[0].indexOf('data:') !== -1) {
            return false; // cached url from image cropper
        } else {
            return true; // Remote image server relative path
        }
    }

    exports.default = Ember.Helper.helper(avatarSwitcher);
});