define('excelixis-client/routes/index', ['exports', 'ember-route-history/mixins/routes/route-history'], function (exports, _routeHistory) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_routeHistory.default, {
        session: Ember.inject.service(),
        beforeModel: function beforeModel() {
            var authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
            try {
                if (authenticator.getTokenData(this.get('session').get('data.authenticated.token'))) {
                    this.transitionTo('dashboard');
                }
            } catch (e) {
                this.transitionTo('login');
            }
        }
    });
});