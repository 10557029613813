define('excelixis-client/helpers/level-icon', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.levelIcon = levelIcon;
    function levelIcon(params /*, hash*/) {
        var lvl = params[0];
        var avatars = ['assets/images/levelAvatar/1.png', 'assets/images/levelAvatar/2.png', 'assets/images/levelAvatar/3.png', 'assets/images/levelAvatar/4.png', 'assets/images/levelAvatar/5.png', 'assets/images/levelAvatar/6.png'];
        if (lvl <= 6 && lvl >= 1) {
            // for brocoli fingerprinting issue
            return avatars[lvl - 1];
        } else {
            return 'assets/images/owl.png';
        }
    }

    exports.default = Ember.Helper.helper(levelIcon);
});