define('excelixis-client/components/lvl-progress-tree', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        rootUrl: _environment.default.rootURL,
        isMobile: Ember.computed(function () {
            return Ember.$(window).width() < _environment.default.APP.widthThreshold ? true : false;
        }),
        isMissionCompleted: Ember.computed(function () {
            // FIXME: Get data from 'mission' model.
            return false;
        }).property('missionCompletedTimePulse'),
        missionCompletedMetronome: function () {
            Ember.run.later(this, function () {
                this.notifyPropertyChange('missionCompletedTimePulse');
                this.missionCompletedMetronome();
            }, 1000);
        }.on('init'),
        levelObserver: Ember.observer('userLevel', function () {
            this.send('computeLevel');
        }),
        pointsObserver: Ember.observer('points', function () {
            this.send('computeLevel');
        }),
        init: function init() {
            this._super.apply(this, arguments);
            this.send('computeLevel');
            if (this.get('isMobile')) {
                Ember.run.schedule("afterRender", this, function () {
                    Ember.$('.background-transp').removeClass('background-transp');
                });
            }
        },

        actions: {
            computeLevel: function computeLevel() {
                var _this = this;

                var that = this;
                Ember.run.schedule("afterRender", this, function () {
                    if (that.get('userLevel') >= 0 && that.get('userLevel') <= that.get('level').get('content').length) {
                        that.get('level').forEach(function (lvl, i) {
                            if (i + 1 == that.get('userLevel')) {
                                // current user lvl
                                that.set('userLevelAlias', lvl.get('alias'));
                                Ember.$('div.progress-tree .lvl-number:eq(' + i + ')').addClass('green-plant');
                                Ember.$('div.progress-tree .lvl-title > span:eq(' + i + ')').addClass('green-plant').addClass('bold').addClass('lvl-bigger-font');
                                that.send('changeIcon', i, true);
                            } else if (i < that.get('userLevel')) {
                                Ember.$('div.progress-tree .lvl-number:eq(' + i + ')').addClass('xaki');
                                Ember.$('div.progress-tree .lvl-title > span:eq(' + i + ')').addClass('xaki').addClass('bold');
                                that.send('changeIcon', i, false);
                            }
                        });
                        _this.send('treeProgressAnimation', that.get('userLevel'), 0);
                    }
                });
            },
            treeProgressAnimation: function treeProgressAnimation(userLevel, p) {
                // Recursive magic animation
                var that = this;
                if (userLevel == p) {
                    return;
                } else if (userLevel - 1 == p) {
                    var divisor = this.get('level').objectAt(1).get('minPoints');
                    var lvlPoints = this.get('points') - this.get('level').objectAt(p).get('minPoints');
                    var progr = Math.ceil(lvlPoints / divisor * 100).toString() + '%';
                    Ember.$('div.progress-tree .progress .tree-progress-bar:eq(' + p + ')').animate({ width: progr }, { duration: 250 });
                } else {
                    Ember.$('div.progress-tree .progress .tree-progress-bar:eq(' + p + ')').animate({ width: '100%' }, { duration: 250, complete: function complete() {
                            that.send('treeProgressAnimation', userLevel, ++p);
                        } });
                }
            },
            changeIcon: function changeIcon(i, isGreen) {
                // for brocoli fingerprinting issue
                if (isGreen) {
                    var iconsGreen = ['assets/images/tree-icons/tree1-green.png', 'assets/images/tree-icons/tree2-green.png', 'assets/images/tree-icons/tree3-green.png', 'assets/images/tree-icons/tree4-green.png', 'assets/images/tree-icons/tree5-green.png', 'assets/images/tree-icons/tree6-green.png'];
                    if (i >= 0 && i <= 5) {
                        Ember.$('div.progress-tree img:eq(' + i + ')').attr('src', _environment.default.rootURL + iconsGreen[i]);
                    } else {
                        Ember.$('div.progress-tree img:eq(' + i + ')').attr('src', _environment.default.rootURL + iconsGreen[0]);
                    }
                } else {
                    var iconsPassed = ['assets/images/tree-icons/tree1-passed.png', 'assets/images/tree-icons/tree2-passed.png', 'assets/images/tree-icons/tree3-passed.png', 'assets/images/tree-icons/tree4-passed.png', 'assets/images/tree-icons/tree5-passed.png', 'assets/images/tree-icons/tree6-passed.png'];
                    if (i >= 0 && i <= 5) {
                        Ember.$('div.progress-tree img:eq(' + i + ')').attr('src', _environment.default.rootURL + iconsPassed[i]);
                    } else {
                        Ember.$('div.progress-tree img:eq(' + i + ')').attr('src', _environment.default.rootURL + iconsPassed[0]);
                    }
                }
            },
            goHome: function goHome() {
                var startTaskButton = Ember.$('span.startTaskButton');
                if (startTaskButton.length == 0) {
                    Ember.$('ul.navbar-nav > li.dash-header-item').removeClass('selected');
                    this.get('goHome')(); // go to father's Controller action
                } else {
                    startTaskButton.trigger('click');
                }
            }
        }
    });
});