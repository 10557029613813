define('excelixis-client/components/task-article', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        API_HOST: _environment.default.APP.API_HOST,
        API_DIR: _environment.default.APP.API_DIR,
        complete: Ember.inject.service(),
        store: Ember.inject.service(),
        appTitles: Ember.inject.service(),
        splashPage: true,
        rootUrl: _environment.default.rootURL,
        index: 0,
        buttonTextNext: Ember.computed(function () {
            return 'ΕΠΟΜΕΝΟ ' + (this.get('index') + 1) + '/' + this.get('task').get('articles').length;
        }),
        init: function init() {
            this._super.apply(this, arguments);
            if (this.get('index') == this.get('task').get('articles').length - 1) {
                this.set('buttonTextNext', 'ΤΟ ΔΙΑΒΑΣΑ');
            }
            // PUT HTTP COMMAND FOR PLAYER
            var that = this;
            var plTask = null;
            this.set('playerTasks', this.get('store').peekAll('playerTask'));
            this.get('playerTasks').forEach(function (playerTask) {
                if (playerTask.get('task') === that.get('task')) {
                    plTask = playerTask;
                }
            });
            if (plTask === null) {
                plTask = this.get('store').createRecord('playerTask', {
                    startDate: new Date(),
                    player: this.get('player'),
                    endDate: '',
                    points: '',
                    task: this.get('task')
                });
            } else {
                plTask.set('startDate', new Date());
            }
            this.set('playerTask', plTask);
            this.get('playerTask').save();
        },

        title: Ember.computed(function () {
            return this.get('task').get('articles').get('firstObject').get('title');
        }),
        content: Ember.computed(function () {
            var content = $('<textarea />').html(this.get('task').get('articles').get('firstObject').get('content')).text();
            return content;
        }),
        actions: {
            startPage: function startPage() {
                var _this = this;

                this.set('splashPage', false);
                // More than 2 times play = Game Over my man!
                this.get('playerTask').set('entries', this.get('playerTask').get('entries') + 1);
                if (this.get('playerTask').get('entries') > 1) {
                    this.get('complete').set('gamePoints', 0); // before completedTaskId must be
                    this.get('complete').set('completedTaskId', this.get('task').get('id') + this.get('appTitles').get('againSubfix')); // Notify Task completion
                    // PUT REQUEST
                    this.get('playerTask').set('endDate', new Date());
                    this.get('playerTask').set('points', 0);
                }
                this.get('playerTask').save();
                Ember.run.schedule('afterRender', this, function () {
                    if (_this.get('index') < 1) {
                        Ember.$('div.row.read-buttons .back').addClass('disable');
                    }
                });
            },
            next: function next(goNext) {
                this.set('index', this.get('index') + parseInt(goNext ? 1 : -1));
                var curIndex = this.get('index');
                if (curIndex == this.get('task').get('articles').length) {
                    // no more articles
                    this.get('complete').set('gamePoints', this.get('task.points')); // before completedTaskId must be
                    this.get('complete').set('completedTaskId', this.get('task').get('id')); // Notify Task completion
                    this.set('popupDataCompon', {
                        isActive: true,
                        taskId: this.get('task.id'),
                        taskAlias: this.get('task.alias'),
                        taskDescr: this.get('task.winText'),
                        taskDescr2: '',
                        taskPoints: this.get('task.points'),
                        won: true
                    }); // Parent's Modal Controller set property
                    // PUT REQUEST
                    this.get('playerTask').set('endDate', new Date());
                    this.get('playerTask').set('points', this.get('task.points'));
                    this.get('playerTask').save();
                } else {
                    // go to the next article
                    if (curIndex == this.get('task').get('articles').length - 1) {
                        this.set('buttonTextNext', 'ΤΟ ΔΙΑΒΑΣΑ');
                    } else {
                        this.set('buttonTextNext', 'ΕΠΟΜΕΝΟ ' + (curIndex + 1) + '/' + this.get('task').get('articles').length);
                    }
                    var content = $('<textarea />').html(this.get('task').get('articles').objectAt(curIndex).get('content')).text();
                    this.set('content', content);
                    this.set('title', this.get('task').get('articles').objectAt(curIndex).get('title'));
                }
                if (curIndex < 1) {
                    Ember.$('div.row.read-buttons .back').addClass('disable');
                } else {
                    Ember.$('div.row.read-buttons .back').removeClass('disable');
                }
            }
        }
    });
});