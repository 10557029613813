define('excelixis-client/components/task-multiple-qa', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        API_HOST: _environment.default.APP.API_HOST,
        API_DIR: _environment.default.APP.API_DIR,
        timer: Ember.inject.service(),
        store: Ember.inject.service(),
        complete: Ember.inject.service(),
        taskHeaderInfo: Ember.inject.service(),
        appTitles: Ember.inject.service(),
        splashPage: true,
        correctAnswers: 0,
        rootUrl: _environment.default.rootURL,
        qaIndex: 0,
        totalPoints: 0,
        answeredCurrent: false,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            var that = this;
            var quizId = parseInt(this.get('task').get('quizzes').get('firstObject').id);
            this.get('store').findRecord('quiz', quizId).then(function (response) {
                that.set('qaModel', response);
            });
            // PUT HTTP COMMAND FOR PLAYER
            var plTask = null;
            this.set('playerTasks', this.get('store').peekAll('playerTask'));
            this.get('playerTasks').forEach(function (playerTask) {
                if (playerTask.get('task') === that.get('task')) {
                    plTask = playerTask;
                }
            });
            if (plTask === null) {
                plTask = this.get('store').createRecord('playerTask', {
                    startDate: new Date(),
                    player: this.get('player'),
                    endDate: '',
                    points: '',
                    task: this.get('task')
                });
            } else {
                plTask.set('startDate', new Date());
            }
            this.set('playerTask', plTask);
            this.get('playerTask').save();
            Ember.run.schedule('afterRender', this, function () {
                _this.get('timer').stop(); // stop timer if is existed from previous task
            });
        },

        timeObserver: Ember.observer('timer.countdownDuration', function () {
            if (this.get('timer.countdownDuration') == 0) {
                this.get('complete').set('gamePoints', this.get('totalPoints')); // before completedTaskId must be
                this.get('complete').set('completedTaskId', this.get('task').get('id')); // Notify Task completion
                var that = this;
                setTimeout(function () {
                    that.set('popupDataCompon', {
                        isActive: true,
                        taskId: that.get('task.id'),
                        taskAlias: that.get('task.alias'),
                        taskDescr: that.get('task.winText'),
                        taskDescr2: '',
                        taskPoints: that.get('totalPoints'),
                        won: true
                    }); // Parent's Modal Controller set property
                }, 1000);
            }
        }),
        actions: {
            startPage: function startPage() {
                var _this2 = this;

                this.set('splashPage', false);
                var questions = this.get('qaModel').get('questions');
                this.set('question', questions.get('firstObject').data.content);
                this.set('answersForQa', questions.get('firstObject').get('answers'));
                // IMAGE LOADER
                this.get('complete').startImageLoader();
                Ember.run.schedule('afterRender', this, function () {
                    var that = _this2;
                    // When all images have been loaded DO . . .
                    $('#taskModal').imagesLoaded({ background: true }, function () {
                        that.get('complete').stopImageLoader();
                        that.get('timer').start(that.get('task').get('duration')); // Dynamic task duration
                    });
                    _this2.get('taskHeaderInfo').show();
                    _this2.get('taskHeaderInfo').set('totalItems', questions.length);
                    _this2.get('taskHeaderInfo').set('restItems', questions.length);
                    // this.get('timer').start(this.get('task').get('duration')); // EINAI PANOY^
                });
                // More than 2 times play = Game Over my man!
                this.get('playerTask').set('entries', this.get('playerTask').get('entries') + 1);
                if (this.get('playerTask').get('entries') > 1) {
                    this.get('complete').set('gamePoints', 0); // before completedTaskId must be
                    this.get('complete').set('completedTaskId', this.get('task').get('id') + this.get('appTitles').get('againSubfix')); // Notify Task completion
                    // PUT REQUEST
                    this.get('playerTask').set('endDate', new Date());
                    this.get('playerTask').set('points', 0);
                }
                this.get('playerTask').save();
            },
            answer: function answer(answerId) {
                this.set('answeredCurrent', true);
                this.set('error', false);
                var that = this;
                var questions = this.get('qaModel').get('questions');
                Ember.$('.answer-style').addClass('disable-click');
                questions.objectAt(this.get('qaIndex')).get('answers').forEach(function (answer, index) {
                    if (answer.get('isCorrect')) {
                        if (answerId == index) {
                            // user answer is correct!
                            that.set('correctAnswers', that.get('correctAnswers') + 1);
                            that.set('totalPoints', that.get('totalPoints') + that.get('task').get('points') / questions.length);
                        }
                        Ember.$('.answer-style:eq(' + index + ')').addClass('correct');
                    } else {
                        if (answerId == index) {
                            Ember.$('.answer-style:eq(' + index + ')').addClass('fail');
                        }
                    }
                });
            },
            next: function next() {
                var that = this;
                var questions = this.get('qaModel').get('questions');
                if (this.get('qaIndex') == questions.length - 2) {
                    Ember.$('#nextQA').text('ΤΕΛΟΣ');
                }
                if (this.get('answeredCurrent')) {
                    this.set('error', false);
                    this.toggleProperty('answeredCurrent');
                    var tmp = this.get('qaIndex');
                    var nextIndex = tmp + 1;
                    this.get('taskHeaderInfo').set('completedItems', nextIndex);
                    this.get('taskHeaderInfo').set('restItems', questions.length - nextIndex);
                    if (tmp >= questions.length - 1) {
                        // End of Quiz
                        this.get('complete').set('gamePoints', this.get('totalPoints')); // before completedTaskId must be
                        this.get('complete').set('completedTaskId', this.get('task').get('id')); // Notify Task completion
                        Ember.$('#nextQA').addClass('disable');
                        this.set('qaIndex', nextIndex);
                        setTimeout(function () {
                            if (that.get('correctAnswers') > 0) {
                                that.set('popupDataCompon', {
                                    isActive: true,
                                    taskId: that.get('task.id'),
                                    taskAlias: that.get('task.alias'),
                                    taskDescr: that.get('task.winText'),
                                    taskDescr2: '',
                                    taskPoints: that.get('totalPoints'),
                                    won: true
                                }); // Parent's Modal Controller set property
                            } else {
                                that.set('popupDataCompon', {
                                    isActive: true,
                                    taskId: that.get('task.id'),
                                    taskAlias: that.get('task.alias'),
                                    taskDescr: that.get('task.loseText'),
                                    taskDescr2: '',
                                    taskPoints: 0,
                                    won: false
                                }); // Parent's Modal Controller set property
                            }
                        }, 2000);
                        // PUT REQUEST
                        this.get('playerTask').set('endDate', new Date());
                        this.get('playerTask').set('points', this.get('totalPoints'));
                        this.get('playerTask').save();
                        return;
                    }
                    Ember.$('.answer-style').removeClass('disable-click');
                    this.set('qaIndex', nextIndex);
                    var object = questions.objectAt(this.get('qaIndex'));
                    this.set('question', object.get('content'));
                    this.set('answersForQa', object.get('answers'));
                } else {
                    this.set('error', true);
                }
            }
        }
    });
});