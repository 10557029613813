define('excelixis-client/models/rank', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        firstName: _emberData.default.attr('String'),
        lastName: _emberData.default.attr('String'),
        jobPosition: _emberData.default.attr(),
        avatar: _emberData.default.attr(), // TODO set the jobPosition and avatar relationship
        totalScore: _emberData.default.attr('Number')
    });
});