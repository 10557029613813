define('excelixis-client/components/prize-card', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        background: Ember.computed(function () {
            switch (this.get('bg')) {// for brocoli fingerprinting issue
                case 'green_card.png':
                    return _environment.default.rootURL + 'assets/images/green_card.png';
                case 'blue_card.png':
                    return _environment.default.rootURL + 'assets/images/blue_card.png';
                case 'pink_card.png':
                    return _environment.default.rootURL + 'assets/images/pink_card.png';
                default:
                    return _environment.default.rootURL + 'assets/images/green_card.png';
            }
        })
    });
});