define('excelixis-client/components/crop-upload-image', ['exports', 'ember-cli-image-cropper/components/image-cropper'], function (exports, _imageCropper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _imageCropper.default.extend({
        imageFile: null,
        toCrop: false,
        store: Ember.inject.service(),
        appTitles: Ember.inject.service(),
        getCroppedCanvasProp: {
            width: 150,
            height: 150,
            imageSmoothingEnabled: false,
            imageSmoothingQuality: 'high'
        },
        init: function init() {
            this._super.apply(this, arguments);
            this.set('submitImage', false);
        },

        // override default options of cropper
        aspectRatio: 1,
        minCropBoxWidth: 150,
        minCropBoxHeight: 150,
        cropperContainer: '.cropper-container > div > img',
        actions: {
            getCroppedAvatar: function getCroppedAvatar() {
                // Internet Explorer 6-11
                var isIE = /*@cc_on!@*/false || !!document.documentMode;
                if (isIE) {
                    var blobData = this.$(this.get('cropperContainer')).cropper('getCroppedCanvas', this.get('getCroppedCanvasProp')).msToBlob();
                    this.send('updateModel', blobData);
                }
                var that = this;
                this.$(this.get('cropperContainer')).cropper('getCroppedCanvas', this.get('getCroppedCanvasProp')).toBlob(function (blob) {
                    that.send('updateModel', blob);
                });
            },
            back: function back() {
                this.set('isUploadImageTempl', false);
            },
            fileLoaded: function fileLoaded(file) {
                this.set('imageFile', file.data);
                this.set('toCrop', true);
                Ember.$('#cropperHeader').append('<span> > ΕΠΕΞΕΡΓΑΣΙΑ</span>');
            },
            filePicking: function filePicking() {
                if (this.get('toCrop')) {
                    Ember.$('#cropperHeader span:last-child').remove();
                }
                this.set('toCrop', false);
            },
            updateModel: function updateModel(formData) {
                var container = this.$(this.get('cropperContainer'));
                var croppedImage = container.cropper('getCroppedCanvas', this.get('getCroppedCanvasProp'));
                var croppedPath = croppedImage.toDataURL(); // base64 encoded form
                if (this.get('avatar') !== null) {
                    this.get('avatar').set('file', formData);
                    this.get('avatar').set('path', croppedPath);
                } else {
                    var avatar = this.get('store').createRecord('avatar', {
                        alias: 'avatarOfPlayerId' + this.get('routeModel.player').get('id'),
                        path: croppedPath,
                        file: formData
                    });
                    this.set('avatar', avatar);
                }
                // Here could be stored but they didn't wanted after the great implemenation...Bliax
                this.set('submitImage', true);
            }
        }
    });
});