define('excelixis-client/controllers/dashboard/player-info', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        API_HOST: _environment.default.APP.API_HOST,
        API_DIR: _environment.default.APP.API_DIR,
        isUploadImageTempl: false,
        croppedAvatar: null,
        complete: Ember.inject.service(),
        validator: Ember.inject.service(),
        appTitles: Ember.inject.service(),
        submitObserver: Ember.observer('hasFirst', 'hasLast', 'hasAvatar', 'hasStore', 'hasJob', function () {
            if (this.get('hasFirst') && this.get('hasLast') && this.get('hasAvatar') && this.get('hasStore') && this.get('hasJob')) {
                Ember.$('#submit-button').removeClass('disable');
                if (this.get('croppedAvatar')) {
                    this.send('canSubmitDelay');
                }
            }
        }),
        fileUploadObserver: Ember.observer('isUploadImageTempl', function () {
            if (this.get('croppedAvatar')) {
                this.set('hasAvatar', true);
                this.set('avatar', this.get('croppedAvatar'));
                this.notifyPropertyChange('hasAvatar');
            }
            if (this.get('earn') <= -1) {
                this.send('canSubmitDelay');
            }
        }),
        firstNameObserver: Ember.observer('firstName', function () {
            this.set('hasFirst', true);
            this.set('errorMessage', '');
            return this.get('model.player').get('firstName');
        }),
        lastNameObserver: Ember.observer('lastName', function () {
            this.set('hasLast', true);
            this.set('errorMessage', '');
        }),
        shopbserver: Ember.observer('shop', function () {
            if (this.get('shop') !== null) {
                this.set('hasStore', true);
                this.set('errorMessage', '');
            } else {
                this.set('hasStore', false);
            }
        }),
        jobObserver: Ember.observer('jobPosition', function () {
            if (this.get('jobPosition') !== null) {
                this.set('hasJob', true);
                this.set('errorMessage', '');
            } else {
                this.set('hasJob', false);
            }
        }),
        actions: {
            matchShop: function matchShop(shop, term) {
                //returns the position of the search term in theshop address string.
                //If not found, it will return -1
                return shop.get('address').toLowerCase().indexOf(term.toLowerCase());
            },
            matchJob: function matchJob(job, term) {
                return job.get('alias').toLowerCase().indexOf(term.toLowerCase());
            },
            chooseAvatar: function chooseAvatar(avatar) {
                this.set('hasAvatar', true);
                this.set('avatar', avatar);
            },
            cancel: function cancel() {
                this.transitionToRoute('dashboard.index');
            },
            submit: function submit() {
                var tjat = this;
                if ((!this.get('validator').isSet(this.get('firstName')) || !this.get('validator').isSet(this.get('lastName')) || !this.get('validator').isAlpha(this.get('firstName')) || !this.get('validator').isAlpha(this.get('lastName'))) && (this.get('firstName').length == 0 || this.get('lastName').length == 0)) {
                    this.set('errorMessage', 'Μόνο χαρακτήρες στο Όνομα παρακαλώ.');
                    return;
                }
                if (!this.get('hasStore')) {
                    this.set('errorMessage', 'Επιλέξτε κατάστημα παρακαλώ.');
                    return;
                }
                if (!this.get('hasJob')) {
                    this.set('errorMessage', 'Επιλέξτε θέση εργασίας παρακαλώ.');
                    return;
                }
                if (this.get('croppedAvatar') !== null) {
                    this.get('model').player.set('avatar', this.get('avatar')); // SET THE AVATAR HERE
                    this.get('model.player').get('avatar').save().then(function () {
                        //image success upload
                        tjat.send('savePlayerModel', tjat);
                    }, function () {
                        // fail upload image
                        tjat.set('errorMessage', 'Υπήρξε κάποιο πρόβλημα με το ανέβασμα της εικόνας.<br/>Δοκιμάστε πάλι παρακαλώ.');
                    });
                } else {
                    this.send('savePlayerModel', this);
                }
            },
            savePlayerModel: function savePlayerModel(tjat) {
                tjat.get('model').player.set('firstName', tjat.get('firstName'));
                tjat.get('model').player.set('lastName', tjat.get('lastName'));
                tjat.get('model').player.set('shop', tjat.get('shop'));
                tjat.get('model').player.set('jobPosition', tjat.get('jobPosition'));
                tjat.get('model').player.set('avatar', tjat.get('avatar'));
                tjat.get('model').player.save().then(function () {
                    if (tjat.get('earn') > -1) {
                        // IF IT IS A TASK
                        var points = void 0;
                        var fillTask = void 0;
                        tjat.get('model').tasks.forEach(function (task) {
                            if (task.get('taskType').get('alias') == 'ACTION_PROFILE') {
                                points = task.get('points');
                                fillTask = task;
                            }
                        });
                        // Notify Task completion
                        tjat.get('complete').set('gamePoints', points); // before completedTaskId must be
                        tjat.get('complete').set('completedTaskId', fillTask.get('id'));
                        // PUT HTTP COMMAND FOR PLAYER
                        var playerTask = tjat.get('store').createRecord('playerTask', {
                            startDate: new Date(),
                            player: tjat.get('model').player,
                            endDate: new Date(),
                            points: points,
                            entries: 1,
                            task: fillTask
                        });
                        playerTask.save();
                    }
                    tjat.transitionToRoute('dashboard');
                });
            },
            uploadImageTempl: function uploadImageTempl() {
                this.toggleProperty('isUploadImageTempl');
            },
            canSubmitDelay: function canSubmitDelay() {
                setTimeout(function () {
                    Ember.$('#submit-button').removeClass('disable');
                }, 300); // liquid fire animation duration
            }
        }
    });
});