define('excelixis-client/components/logout-hover', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        isMobile: Ember.computed(function () {
            return Ember.$(window).width() < _environment.default.APP.widthThreshold ? true : false;
        }),
        actions: {
            invalidateSession: function invalidateSession() {
                this.get('session').invalidate();
                console.warn('User session is been invalidated');
                location.reload(); //refresh page
            }
        }
    });
});