define('excelixis-client/controllers/taskmodal', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        API_HOST: _environment.default.APP.API_HOST,
        API_DIR: _environment.default.APP.API_DIR,
        timer: Ember.inject.service(),
        taskHeaderInfo: Ember.inject.service(),
        DailyTemplate: true,
        rootUrl: _environment.default.rootURL,
        popupDataController: {
            isActive: false,
            taskId: '',
            taskAlias: '',
            taskDescr: '',
            taskDescr2: '',
            taskPoints: 0,
            won: false
        },
        isMobile: Ember.computed(function () {
            return Ember.$(window).width() < _environment.default.APP.widthThreshold;
        }),
        taskObserver: Ember.observer('model.taskType.alias', function () {
            switch (this.get('model.taskType.alias')) {
                case 'GAME_LUCKY':
                    this.set('isWheel', true);
                    this.set('isMultipleQa', false);this.set('isArticle', false);
                    this.set('islogoQa', false);this.set('isWordFind', false);
                    break;
                case 'QUIZ':
                    this.set('isMultipleQa', true);
                    this.set('isWheel', false);this.set('isArticle', false);
                    this.set('islogoQa', false);this.set('isWordFind', false);
                    break;
                case 'ACTION_READ':
                    this.set('isArticle', true);
                    this.set('isMultipleQa', false);this.set('isWheel', false);
                    this.set('islogoQa', false);this.set('isWordFind', false);
                    break;
                case 'QUIZ_IMAGE':
                    this.set('islogoQa', true);
                    this.set('isMultipleQa', false);this.set('isWheel', false);
                    this.set('isArticle', false);this.set('isWordFind', false);
                    break;
                case 'GAME_SKILL':
                    this.set('isWordFind', true);
                    this.set('isMultipleQa', false);this.set('isWheel', false);
                    this.set('isArticle', false);this.set('islogoQa', false);
                    break;
                default:
                    this.set('isWordFind', false);
                    this.set('isMultipleQa', false);this.set('isWheel', false);
                    this.set('isArticle', false);this.set('islogoQa', false);
            }
        }),
        actions: {
            exit: function exit() {
                // dashboard.index Route's action
                this.send('closeTaskModal');
                this.get('timer').stop(); // stop timer
                this.set('popupDataController.isActive', false); // reset template popup
                this.get('taskHeaderInfo').reset(); // reset task-Header-Info template
            },
            exitPopup: function exitPopup() {
                this.set('isPopupController', false);
                this.send('exit');
            }
        }
    });
});