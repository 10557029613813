define('excelixis-client/helpers/day-format', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dayFormat = dayFormat;
  function dayFormat(params /*, hash*/) {
    return moment(params[0]).format('DD.MM.YYYY/ HH:mm a');
  }

  exports.default = Ember.Helper.helper(dayFormat);
});