define('excelixis-client/initializers/reopen-model', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: 'reopen-model',
        initialize: function initialize() {
            _emberData.default.Model.reopen({
                oldRelationships: null,
                resetOldRelationships: function resetOldRelationships() {
                    var oldRelationships = {};

                    Ember.run.schedule('actions', this, function () {
                        this.eachRelationship(function (name, descriptor) {
                            if (descriptor.kind === 'belongsTo') {
                                var id = this.belongsTo(name).id();

                                if (!id) {
                                    id = null;
                                }

                                oldRelationships[name] = id;
                            }
                        }, this);

                        this.set('oldRelationships', oldRelationships);
                    });
                },
                getChangedRelationships: function getChangedRelationships() {
                    var oldRelationships = this.get('oldRelationships'),
                        changedRelationships = {};

                    this.eachRelationship(function (name, descriptor) {
                        if (descriptor.kind === 'belongsTo') {
                            var id = this.belongsTo(name).id();

                            if (oldRelationships[name] !== id) {
                                changedRelationships[name] = id;
                            }
                        }
                    }, this);

                    return changedRelationships;
                },
                ready: function ready() {
                    this.resetOldRelationships();
                },
                didCreate: function didCreate() {
                    this.resetOldRelationships();
                },
                didLoad: function didLoad() {
                    this.resetOldRelationships();
                },
                didUpdate: function didUpdate() {
                    this.resetOldRelationships();
                },
                rollbackAttributes: function rollbackAttributes() {
                    var oldRelationships = this.get('oldRelationships');

                    this.eachRelationship(function (name, descriptor) {
                        if (descriptor.kind === 'belongsTo') {
                            var id = oldRelationships[name],
                                value = id;

                            if (id) {
                                var record = this.store.peekRecord(descriptor.type, id);

                                if (record) {
                                    value = record;
                                }
                            }

                            this.set(name, value);
                        }
                    }, this);

                    this.resetOldRelationships();

                    this._super();
                }
            });
        }
    };
});