define('excelixis-client/controllers/dashboard/index', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        dailyTaskTypeAlias: 'QUIZ_DAILY',
        appTitles: Ember.inject.service(),
        complete: Ember.inject.service(),
        pageSelected: 0,
        init: function init() {
            this._super();
            moment.locale('el'); // Set moment() Globally Locale to Greek.
            var that = this;
            window.onpopstate = function () {
                // always cancels the popup game or not playing actually
                that.get('complete').set('gameIsActive', false);
            };
        },
        isMobile: Ember.computed(function () {
            return Ember.$(window).width() < _environment.default.APP.widthThreshold;
        }),
        isMissionCompleted: Ember.computed(function () {
            // FIXME: Get data from 'mission' model.
            return false;
        }).property('missionCompletedTimePulse'),
        missionCompletedMetronome: function () {
            Ember.run.later(this, function () {
                this.notifyPropertyChange('missionCompletedTimePulse');
                this.missionCompletedMetronome();
            }, 1000);
        }.on('init'),
        completeTasksObserver: Ember.observer('complete.completedTaskId', function () {
            if (this.get('complete').get('gameIsActive')) {
                // MUST BE ACTIVE GAME
                this.get('complete').set('gameIsActive', false);
                var that = this;
                var completedTaskId = this.get('complete.completedTaskId');
                var againSubfix = this.get('appTitles').get('againSubfix');
                if (completedTaskId.indexOf(againSubfix) !== -1) {
                    completedTaskId = completedTaskId.substring(0, completedTaskId.length - againSubfix.length);
                }
                this.get('model.tasks').forEach(function (task, i) {
                    if (task.get('id') == completedTaskId) {
                        that.get('model.tasks').objectAt(i).set('isCompletedByPlayer', true);
                    }
                });
                var completedLastIndex = -1;
                this.get('model.tasks').forEach(function (task, i) {
                    if (task.get('isCompletedByPlayer')) {
                        completedLastIndex = i; // FIND THE LATEST COMPLETED TASK AGAIN
                    }
                    that.get('model.tasks').objectAt(i).set('isLocked', false); // defautlValue again
                });
                // current Available unLocked task
                Ember.$('ol#pagination li a').removeClass('indexed').removeAttr('title');
                var firstAvailable = completedLastIndex + 1;
                var firstAvailablePager = this.get('isMobile') ? firstAvailable : firstAvailable / 4;
                Ember.$('ol#pagination li a:eq(' + Math.floor(firstAvailablePager).toString() + ')').addClass('indexed').attr('title', this.get('appTitles').get('continueHover')).trigger('click');
                // MARK AS LOCKED TASKS
                this.get('model.tasks').forEach(function (task, i) {
                    if (task.get('taskType').get('alias') == that.get('dailyTaskTypeAlias')) {
                        // dailyQuizzes
                        var diffFromStart = moment().diff(task.get('startDate'), 'days', true);
                        var diffFromEnd = moment().diff(task.get('endDate'), 'days', true);
                        if (i > completedLastIndex + 1 || diffFromStart < 0 || diffFromEnd > 0) {
                            //same check at Route
                            task.set('isLocked', true);
                        }
                    } else {
                        // tasks
                        if (i > completedLastIndex + 1) {
                            that.get('model.tasks').objectAt(i).set('isLocked', true);
                        }
                    }
                });
                // Dashboard sideBar info about user task completion
                this.get('model.player').set('tasksCompleted', completedLastIndex + 1);
                this.set('mobileIndexActive', completedLastIndex + 1);
                // sort model Array based on 'sort' property AGAIN
                var tmp = [];
                this.get('model.tasks').forEach(function (task) {
                    tmp[task.get('sort')] = task;
                });
                Ember.set(this.get('model.tasks'), '', tmp); // re-order tasks based on sort attribute
            }
        }),
        tasks: Ember.computed(function () {
            if (this.get('isMobile')) {
                return this.get('model.tasks').slice(0, 1);
            } else {
                return this.get('model.tasks').slice(0, 4);
            }
        }),
        pagers: Ember.computed(function () {
            var pagerLength = [];
            for (var i = 0; i < this.get('model.tasks').get('content').length; i++) {
                if (this.get('isMobile')) {
                    pagerLength.push(i); // one by one
                } else {
                    if (i % 4 == 0) {
                        pagerLength.push(i);
                    }
                }
            }
            return pagerLength;
        }),
        actions: {
            pager: function pager(taskIndex, pageSelected) {
                var toLeft = '-';
                var toLeftOpposite = '+';
                if (pageSelected < this.get('pageSelected')) {
                    // toRight animation
                    toLeft = '+';
                    toLeftOpposite = '-';
                }
                this.set('pageSelected', pageSelected); // store the active pagination
                Ember.$('ol#pagination li a').removeClass('active');
                Ember.$('ol#pagination li a:eq(' + pageSelected + ')').addClass('active');
                this.set('mobileIndexActive', pageSelected + 1);
                var that = this;
                Ember.$("div.component-parent").animate({ 'margin-left': toLeft + '=1000px' }, 150, function () {
                    Ember.$(this).animate({ 'margin-left': toLeftOpposite + '=2000px' }, 0, function () {
                        if (that.get('isMobile')) {
                            that.set('tasks', that.get('model.tasks').slice(taskIndex, taskIndex + 1));
                        } else {
                            that.set('tasks', that.get('model.tasks').slice(taskIndex, taskIndex + 4));
                        }
                        Ember.$(this).animate({ 'margin-left': toLeft + '=1000px' }, 150);
                    });
                });
            },
            goToModal: function goToModal(id, type) {
                // for player-info or Tasks Route and not for Pop-up daily quizes.
                this.send('OpenTaskModal', id, type);
            },
            swipe: function swipe(goRight) {
                // triggered from my-task component
                var pageSelection = this.get('pageSelected');
                if (goRight) {
                    pageSelection--;
                    if (pageSelection >= 0) {
                        var nextTaskIndex = this.get('isMobile') ? pageSelection : pageSelection * 4;
                        this.send('pager', nextTaskIndex, pageSelection);
                    } else {
                        // go to the end of the pager
                        var taskLength = this.get('model.tasks').get('length') - 1;
                        var _pageSelection = this.get('isMobile') ? taskLength : Math.floor(taskLength / 4);
                        this.send('pager', taskLength, _pageSelection);
                    }
                } else {
                    pageSelection++;
                    var length = this.get('isMobile') ? this.get('model.tasks').get('length') : this.get('model.tasks').get('length') / 4;
                    if (pageSelection < length) {
                        var _nextTaskIndex = this.get('isMobile') ? pageSelection : pageSelection * 4;
                        this.send('pager', _nextTaskIndex, pageSelection);
                    } else {
                        // go at the beginning of pager
                        this.send('pager', 0, 0);
                    }
                }
            }
        }
    });
});