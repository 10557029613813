define("excelixis-client/helpers/style-list-item", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.eleventhListItem = eleventhListItem;
    function eleventhListItem(params /*, hash*/) {
        var index = params[0];
        var mode = params[1];
        var userRank = params[2];
        if (mode == "button") {
            if (userRank == index || userRank + 1 == index) {
                return index > 10 && index < userRank + 1;
            } else if (userRank + 2 == index) {
                return index >= 10 && index < userRank + 3;
            } else {
                return index + 1 == 11;
            }
        } else {
            return index + 1 == mode; // if the Row is the user's, change the css rules at hbs
        }
    }

    exports.default = Ember.Helper.helper(eleventhListItem);
});