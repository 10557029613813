define('excelixis-client/controllers/dashboard', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        API_HOST: _environment.default.APP.API_HOST,
        API_DIR: _environment.default.APP.API_DIR,
        complete: Ember.inject.service(),
        appTitles: Ember.inject.service(),
        rootUrl: _environment.default.rootURL,
        expandedSideMenu: false,
        isMobile: Ember.computed(function () {
            return Ember.$(window).width() < _environment.default.APP.widthThreshold;
        }),
        completeTasksObserver: Ember.observer('complete.completedTaskId', function () {
            var that = this;
            // set user's new totalScore
            var completedTaskId = this.get('complete.completedTaskId');
            var againSubfix = this.get('appTitles').get('againSubfix');
            if (completedTaskId.indexOf(againSubfix) !== -1) {
                completedTaskId = completedTaskId.substring(0, // check for againSubfix and multiple user gameplay
                completedTaskId.length - againSubfix.length);
            }
            this.get('model.tasks').forEach(function (task) {
                if (task.get('id') == completedTaskId) {
                    that.get('model.player').set('totalScore', that.get('model.player').get('totalScore') + that.get('complete.gamePoints'));
                    if (task.get('taskType').get('alias') == 'ACTION_PROFILE') {
                        Ember.$('#edit-profile').removeClass('hide');
                    }
                }
            });
            // find user's level
            this.get('model.level').forEach(function (level) {
                if (level.get('minPoints') <= that.get('model.player').get('totalScore')) {
                    that.get('model.player').set('level', level.get('numberAlias'));
                    that.get('model.player').set('levelTitle', level.get('alias'));
                }
            });
            // find User's rank
            this.get('model.rank').forEach(function (user, index) {
                that.get('model.level').forEach(function () {
                    if (user.get('id') == that.get('model.player').get('id')) {
                        Ember.set(that.get('model.player'), 'playerRank', index + 1);
                    }
                });
            });
            // find User daily completed achievements
            var playerDailyCompleted = 0;
            this.get('model.player').get('playerTasks').forEach(function (task) {
                if (task.get('task').get('taskType').get('alias') == "QUIZ_DAILY" && task.get('points') > 0) {
                    //check success of dailyQuiz
                    ++playerDailyCompleted;
                }
            });
            this.set('playerDailyCompleted', playerDailyCompleted);
        }),
        actions: {
            navMenu: function navMenu(menuId) {
                Ember.$('ul.navbar-nav > li.dash-header-item').removeClass('selected');
                switch (menuId) {
                    case 'dashboard.player-info':
                        this.transitionToRoute(menuId, { queryParams: { earn: -1 } });
                        return;
                    case 'syllabus':
                        this.transitionToRoute('dashboard.' + menuId, { queryParams: { earn: -1 } });
                        return;
                    case -1:
                        this.transitionToRoute('dashboard');
                        break;
                    default:
                        {
                            this.transitionToRoute('dashboard.' + menuId);
                            if (menuId !== 'index') {
                                Ember.$('ul.navbar-nav > li.dash-header-item.' + menuId).addClass('selected');
                            }
                        }
                }
            },
            sideNavMenu: function sideNavMenu(menuId) {
                menuId = menuId == 'player-info' ? 'dashboard.' + menuId : menuId;
                this.send('navMenu', menuId);
                this.send('toggleSideMenu');
            },
            toggleSideMenu: function toggleSideMenu() {
                if (this.get('expandedSideMenu')) {
                    document.getElementById("mySidenav").style.width = "0";
                    Ember.$('#mobileBurger').addClass('glyphicon-menu-hamburger').removeClass('glyphicon-remove');
                } else {
                    document.getElementById("mySidenav").style.width = "75%";
                    Ember.$('#mobileBurger').addClass('glyphicon-remove').removeClass('glyphicon-menu-hamburger');
                }
                this.toggleProperty('expandedSideMenu');
            }
        }
    });
});