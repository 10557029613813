define('excelixis-client/models/avatar', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        alias: _emberData.default.attr('string'),
        path: _emberData.default.attr('string'),
        file: _emberData.default.attr('file', { defaultValue: null })
    });
});