define('excelixis-client/components/my-task', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        API_HOST: _environment.default.APP.API_HOST,
        API_DIR: _environment.default.APP.API_DIR,
        complete: Ember.inject.service(),
        store: Ember.inject.service(),
        classNames: ['task-width'],
        rootUrl: _environment.default.rootURL,
        Background: Ember.computed(function () {
            return Ember.String.htmlSafe('background: url(' + _environment.default.APP.API_HOST + '/' + _environment.default.APP.API_DIR + '/' + this.get('task').get('bgPath') + ');');
        }),
        isDailyQuiz: Ember.computed(function () {
            return 'QUIZ_DAILY' === this.get('task').get('taskType').get('alias');
        }),
        isTimeAvailable: Ember.computed(function () {
            return moment().diff(this.get('task').get('startDate'), 'days', true) >= 0;
        }),
        forToday: Ember.computed(function () {
            var startDate = this.get('task').get('startDate');
            var diff = moment().diff(startDate, 'days', true);
            return diff >= 0 && diff <= 1;
        }),
        availability: Ember.computed(function () {
            return 'ΞΕΚΛΕΙΔΩΝΕΤΑΙ ΣΕ ' + (-moment().diff(this.get('task').get('startDate'), 'days') + 1) + ' ΜΕΡΕΣ';
        }),
        expires: Ember.computed(function () {
            var expires = this.get('task').get('endDate');
            if (moment().diff(expires) < 0) {
                var diffTime = moment(expires).diff();
                var duration = moment.duration(diffTime);
                var days = duration.days(),
                    hrs = duration.hours(),
                    mins = duration.minutes();
                return 'Λήγει σε ' + days + ' μέρες ' + hrs + ' ώρες ' + mins + ' λεπτά';
            } else {
                return '';
            }
        }),
        actions: {
            goToRoute: function goToRoute(id, type) {
                // go to father's Controller action for player-info or Tasks
                if (this.get('task').get('isLocked') || this.get('task').get('isCompletedByPlayer')) {
                    return;
                }
                this.get('complete').set('gameIsActive', true); // starting the game state
                this.get('goToModal')(id, type);
                // For 'GO TO LIBRARY' task only
                if (this.get('task').get('taskType').get('alias') == 'ACTION_LIBRARY') {
                    // Notify Task completion
                    this.get('complete').set('gamePoints', this.get('task').get('points')); // before completedTaskId must be
                    this.get('complete').set('completedTaskId', this.get('task').get('id'));
                    var payload = {
                        startDate: new Date(),
                        player: this.get('player'),
                        endDate: new Date(),
                        points: this.get('task').get('points'),
                        entries: 1,
                        task: this.get('task')
                    };
                    // PUT HTTP COMMAND FOR PLAYER
                    var playerTask = this.get('store').createRecord('playerTask', payload);
                    playerTask.save();
                }
            },
            swipe: function swipe(goRight) {
                this.get('swipe')(goRight);
            }
        },
        hammerOptions: {
            ignoreEvents: ['touchmove', 'touchstart', 'touchend', 'touchcancel'],
            swipe_velocity: 0.3 // default
        },
        gestures: {
            swipeRight: function swipeRight() /*event*/{
                this.send('swipe', true);
                return false; // return `false` to stop bubbling
            },
            swipeLeft: function swipeLeft() /*event*/{
                this.send('swipe', false);
                return false; // return `false` to stop bubbling
            } /*,
              tap(event) { return false; }*/

        }
    });
});