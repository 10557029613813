define('excelixis-client/helpers/available-list-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.availableListItem = availableListItem;
    function availableListItem(params /*, hash*/) {
        var index = params[0];
        var userIndex = params[1];
        var isViewAll = params[2];

        if (isViewAll) {
            return true;
        }
        return index + 1 <= 10 || (userIndex == index || userIndex == index + 1 || userIndex == index + 2) && !isViewAll;
    }

    exports.default = Ember.Helper.helper(availableListItem);
});