define('excelixis-client/routes/dashboard/leaderboard', ['exports', 'excelixis-client/config/environment', 'ember-route-history/mixins/routes/route-history'], function (exports, _environment, _routeHistory) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_routeHistory.default, {
        API_HOST: _environment.default.APP.API_HOST,
        API_DIR: _environment.default.APP.API_DIR,
        beforeModel: function beforeModel() {
            this.store.unloadAll('rank');
        },

        model: function model() {
            return this.store.findAll('rank', { reload: true }).then(function (user) {
                return Ember.RSVP.all(user.getEach('jobPosition')).then(function () {
                    return user;
                });
            });
        },
        setupController: function setupController(controller, model) {
            this._super.apply(this, arguments);
            controller.set('viewAll', false);

            var dashboardModel = this.modelFor('dashboard');
            controller.set('player', dashboardModel.player);
            if (dashboardModel.player.get('avatar') == null) {
                // default avatar
                controller.set('userAvatar', this.get('API_HOST') + '/' + this.get('API_DIR') + '/' + '/assets/mpass/img/avatar/avatar_generic.png');
            } else {
                controller.set('userAvatar', this.get('API_HOST') + '/' + this.get('API_DIR') + '/' + dashboardModel.player.get('avatar').get('path'));
            }
            model.forEach(function (user, index) {
                dashboardModel.level.forEach(function (level) {
                    if (level.data.minPoints <= user.get('totalScore')) {
                        Ember.set(user, 'level', level.data.alias); // set every rank user level
                    }
                });
                if (user.get('id') == dashboardModel.player.get('id')) {
                    Ember.set(dashboardModel.player, 'playerRank', index + 1);
                }
            });
            if (dashboardModel.player.get('playerRank') == 0) {
                // default
                Ember.set(dashboardModel.player, 'playerRank', parseInt(model.get('length')) + 1);
            }
        },

        actions: {
            didTransition: function didTransition() {
                Ember.run.schedule("afterRender", this, function () {
                    Ember.$('div.leaderboard.list-item:eq(0), div.leaderboard.list-item:eq(1), div.leaderboard.list-item:eq(2)').addClass('green-light-list-item');
                    Ember.$('div.leaderboard.list-item .lvl:eq(0), div.leaderboard.list-item .lvl:eq(1), div.leaderboard.list-item .lvl:eq(2)').addClass('green-darker');
                    Ember.$('div.leaderboard.list-item .id:eq(0), div.leaderboard.list-item .id:eq(1), div.leaderboard.list-item .id:eq(2)');
                });
                return true;
            }
        }
    });
});