define('excelixis-client/routes/invalid-url', ['exports', 'ember-route-history/mixins/routes/route-history'], function (exports, _routeHistory) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_routeHistory.default, {
        // beforeModel() {
        //    this.replaceWith('dashboard');   // if 401 goes to Login
        // }
    });
});