define('excelixis-client/components/task-word-find', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        API_HOST: _environment.default.APP.API_HOST,
        API_DIR: _environment.default.APP.API_DIR,
        timer: Ember.inject.service(),
        store: Ember.inject.service(),
        complete: Ember.inject.service(),
        taskHeaderInfo: Ember.inject.service(),
        appTitles: Ember.inject.service(),
        wordCounter: 0,
        splashPage: true,
        rootUrl: _environment.default.rootURL,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.set('wordCounter', 0); // reinitialize every time the counter
            wordfindgame.initializePoints();
            // PUT HTTP COMMAND FOR PLAYER
            var that = this;
            var plTask = null;
            this.set('playerTasks', this.get('store').peekAll('playerTask'));
            this.get('playerTasks').forEach(function (playerTask) {
                if (playerTask.get('task') === that.get('task')) {
                    plTask = playerTask;
                }
            });
            if (plTask === null) {
                plTask = this.get('store').createRecord('playerTask', {
                    startDate: new Date(),
                    player: this.get('player'),
                    endDate: '',
                    points: '',
                    task: this.get('task')
                });
            } else {
                plTask.set('startDate', new Date());
            }
            this.set('playerTask', plTask);
            this.get('playerTask').save();
            Ember.run.schedule('afterRender', this, function () {
                _this.get('timer').stop(); // stop timer if is existed from previous task
            });
        },
        willDestroy: function willDestroy() {
            document.removeEventListener('scoreUpdate', document.pointsListener);
        },

        timeObserver: Ember.observer('timer.countdownDuration', function () {
            if (this.get('timer.countdownDuration') == 0) {
                this.get('complete').set('gamePoints', wordfindgame.getPoints()); // before completedTaskId must be
                this.get('complete').set('completedTaskId', this.get('task').get('id')); // Notify Task completion
                if (wordfindgame.getPoints() > 0) {
                    this.set('popupDataCompon', {
                        isActive: true,
                        taskId: this.get('task.id'),
                        taskAlias: this.get('task.alias'),
                        taskDescr: this.get('task.winText'),
                        taskDescr2: '',
                        taskPoints: wordfindgame.getPoints(),
                        won: true
                    }); // Parent's Modal Controller set property
                } else {
                    this.set('popupDataCompon', {
                        isActive: true,
                        taskId: this.get('task.id'),
                        taskAlias: this.get('task.alias'),
                        taskDescr: this.get('task.loseText'),
                        taskDescr2: '',
                        taskPoints: 0,
                        won: false
                    }); // Parent's Modal Controller set property
                }
                // PUT REQUEST
                this.get('playerTask').set('endDate', new Date());
                this.get('playerTask').set('points', wordfindgame.getPoints());
                this.get('playerTask').save();
            }
        }),
        actions: {
            startPage: function startPage() {
                var _this2 = this;

                this.set('splashPage', false);
                Ember.run.schedule('afterRender', this, function () {
                    _this2.get('taskHeaderInfo').show();
                    _this2.send('puzzleCode');
                    _this2.get('timer').start(_this2.get('task').get('duration')); // Dynamic task duration
                    Ember.$('html').animate({ scrollTop: 0 }, 'slow'); // fix for the touchStart letter
                });
                // More than 2 times play = Game Over my man!
                this.get('playerTask').set('entries', this.get('playerTask').get('entries') + 1);
                if (this.get('playerTask').get('entries') > 1) {
                    this.get('complete').set('gamePoints', 0); // before completedTaskId must be
                    this.get('complete').set('completedTaskId', this.get('task').get('id') + this.get('appTitles').get('againSubfix')); // Notify Task completion
                    // PUT REQUEST
                    this.get('playerTask').set('endDate', new Date());
                    this.get('playerTask').set('points', 0);
                }
                this.get('playerTask').save();
            },

            puzzleCode: function puzzleCode() {
                var words = ['ΕΞΕΛΙΞΗ', 'ΑΝΑΠΤΥΞΗ', 'ΤΟΥΡΙΣΜΟΣ', 'ΕΠΙΧΕΙΡΕΙΝ', 'ΚΑΙΝΟΤΟΜΙΑ', 'ΕΚΠΑΙΔΕΥΣΗ'];
                this.get('taskHeaderInfo').set('totalItems', words.length);
                this.get('taskHeaderInfo').set('restItems', words.length);
                var that = this;
                document.pointsListener = function () {
                    var count = that.get('wordCounter') + 1;
                    that.set('wordCounter', count);
                    that.get('taskHeaderInfo').set('completedItems', count);
                    that.get('taskHeaderInfo').set('restItems', words.length - count);
                    if (count == words.length) {
                        // Finish Game
                        that.get('timer').pause();
                        that.get('complete').set('gamePoints', wordfindgame.getPoints()); // before completedTaskId must be
                        that.get('complete').set('completedTaskId', that.get('task').get('id')); // Notify Task completion
                        setTimeout(function () {
                            that.set('popupDataCompon', {
                                isActive: true,
                                taskId: that.get('task.id'),
                                taskAlias: that.get('task.alias'),
                                taskDescr: that.get('task.winText'),
                                taskDescr2: '',
                                taskPoints: wordfindgame.getPoints(),
                                won: true
                            }); // Parent's Modal Controller set property
                        }, 1500);
                        // PUT REQUEST
                        that.get('playerTask').set('endDate', new Date());
                        that.get('playerTask').set('points', wordfindgame.getPoints());
                        that.get('playerTask').save();
                    }
                };
                document.addEventListener('scoreUpdate', document.pointsListener);
                wordfindgame.create(words, '#puzzle', '#words');
                var puzzleSquare = $('#wordFinder .puzzleSquare');
                puzzleSquare.css({ "font-size": "2em" });
                puzzleSquare.addClass('bold');
                window.onresize = function () {
                    if (window.innerWidth < 1340) {
                        $('#puzzleContainer').css({ "width": "100%" });
                    } else {
                        $('#puzzleContainer').css({ "width": "70%" });
                    }
                };
                $('[data-popup-open]').on('click', function (e) {
                    var targeted_popup_class = Ember.$(this).attr('data-popup-open');
                    $('[data-popup="' + targeted_popup_class + '"]').fadeIn(350);
                    e.preventDefault();
                });
                $('[data-popup-close]').on('click', function (e) {
                    var targeted_popup_class = Ember.$(this).attr('data-popup-close');
                    $('[data-popup="' + targeted_popup_class + '"]').fadeOut(350);
                    e.preventDefault();
                });
                document.body.addEventListener('touchmove', function (event) {
                    event.preventDefault();
                }, false);
                window.onresize();
            }
        }
    });
});