define('excelixis-client/controllers/dashboard/leaderboard', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        API_HOST: _environment.default.APP.API_HOST,
        API_DIR: _environment.default.APP.API_DIR,
        viewAll: false,
        isMobile: Ember.computed(function () {
            return Ember.$(window).width() < _environment.default.APP.widthThreshold ? true : false;
        }),
        displayCongrat: Ember.computed(function () {
            return this.get('player').get('playerRank') <= 25;
        }),
        actions: {
            toggle: function toggle() {
                this.toggleProperty('viewAll');
            }
        }
    });
});