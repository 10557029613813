define('excelixis-client/models/quiz', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        alias: _emberData.default.attr('String'),
        hasFifty: _emberData.default.attr('boolean'),
        hasTip: _emberData.default.attr('boolean'),
        hasNext: _emberData.default.attr('boolean'),
        questions: _emberData.default.hasMany('question', { async: false }),
        task: _emberData.default.belongsTo('task', { async: false })
    });
});