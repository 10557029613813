define('excelixis-client/controllers/dashboard/my-missions', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        rootUrl: _environment.default.rootURL,
        completedPager: Ember.computed(function () {
            var pagerLength = [];
            for (var i = 0; i < this.get('completedList').length; i++) {
                if (Ember.$(window).width() > _environment.default.APP.widthThreshold) {
                    if (i % 4 == 0) {
                        pagerLength.push(i);
                    }
                } else {
                    if (i % 2 == 0) {
                        pagerLength.push(i);
                    }
                }
            }
            return pagerLength;
        }),
        pendingPager: Ember.computed(function () {
            var pagerLength = [];
            for (var i = 0; i < this.get('PendingList').length; i++) {
                if (Ember.$(window).width() > _environment.default.APP.widthThreshold) {
                    if (i % 4 == 0) {
                        pagerLength.push(i);
                    }
                } else {
                    if (i % 2 == 0) {
                        pagerLength.push(i);
                    }
                }
            }
            return pagerLength;
        }),
        actions: {
            completedPager: function completedPager(TaskIndex, pageSelected) {
                if (!(this.get('completedList').length < 4)) {
                    Ember.$('ol#completedPagination li a').removeClass('active');
                    Ember.$('ol#completedPagination li a:eq(' + pageSelected + ')').addClass('active');
                    var that = this;
                    Ember.$("#completedList").animate({ 'margin-left': '-=2000px' }, 200, function () {
                        Ember.$(this).animate({ 'margin-left': '+=4000px' }, 0, function () {
                            // overflow-x at body and z-index:1 at side-bar
                            if (Ember.$(window).width() > _environment.default.APP.widthThreshold) {
                                that.set('completedTasks', that.get('completedList').slice(TaskIndex, TaskIndex + 4));
                            } else {
                                // mobile
                                that.set('completedTasks', that.get('completedList').slice(TaskIndex, TaskIndex + 2));
                            }
                            Ember.$(this).animate({ 'margin-left': '-=2000px' }, 200);
                        });
                    });
                }
            },
            pendingPager: function pendingPager(TaskIndex, pageSelected) {
                if (!(this.get('PendingList').length < 4)) {
                    Ember.$('ol#pendingPagination li a').removeClass('active');
                    Ember.$('ol#pendingPagination li a:eq(' + pageSelected + ')').addClass('active');
                    var that = this;
                    Ember.$("#PendingList").animate({ 'margin-left': '-=2000px' }, 200, function () {
                        Ember.$(this).animate({ 'margin-left': '+=4000px' }, 0, function () {
                            // overflow-x at body and z-index:1 at side-bar
                            if (Ember.$(window).width() > _environment.default.APP.widthThreshold) {
                                that.set('pendingTasks', that.get('PendingList').slice(TaskIndex, TaskIndex + 4));
                            } else {
                                // mobile
                                that.set('pendingTasks', that.get('PendingList').slice(TaskIndex, TaskIndex + 2));
                            }
                            Ember.$(this).animate({ 'margin-left': '-=2000px' }, 200);
                        });
                    });
                }
            },
            goIndex: function goIndex() {
                this.transitionToRoute('dashboard.index');
                Ember.$('ul.nav.navbar-nav .selected').removeClass('selected');
            }
        }
    });
});