define('excelixis-client/routes/dashboard', ['exports', 'ember-route-history/mixins/routes/route-history'], function (exports, _routeHistory) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_routeHistory.default, {
        session: Ember.inject.service(),
        model: function model() {
            var authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
            var userId = void 0;
            try {
                var tokenData = authenticator.getTokenData(this.get('session').get('data.authenticated.token'));
                userId = tokenData.id;
            } catch (e) {
                this.transitionTo('login');
            }
            return Ember.RSVP.hash({
                level: this.store.findAll('level'),
                player: this.store.findRecord('player', userId),
                tasks: this.store.findAll('task').then(function (tasks) {
                    return Ember.RSVP.all(tasks.getEach('taskType')).then(function () {
                        return tasks;
                    });
                }),
                rank: this.store.findAll('rank').then(function (user) {
                    return Ember.RSVP.all(user.getEach('jobPosition')).then(function () {
                        return user;
                    });
                })
            });
        },
        afterModel: function afterModel() {
            this.transitionTo('dashboard.index'); // needless
        },
        setupController: function setupController(controller, model) {
            this._super.apply(this, arguments);
            Ember.set(controller, 'player', model.player);
            Ember.set(controller, 'level', model.level);
            Ember.set(controller, 'tasks', model.tasks);
            Ember.set(controller, 'rank', model.rank);

            // find user's level
            model.level.forEach(function (level) {
                if (level.get('minPoints') <= model.player.get('totalScore')) {
                    Ember.set(model.player, 'level', level.get('numberAlias'));
                    Ember.set(model.player, 'levelTitle', level.get('alias'));
                }
            });
            Ember.set(model.player, 'totalTasks', model.tasks.content.length);
            Ember.set(model.player, 'totalLevel', model.level.content.length);

            // find User rank
            model.rank.forEach(function (user, index) {
                model.level.forEach(function () {
                    if (user.get('id') == model.player.id) {
                        Ember.set(model.player, 'playerRank', index + 1);
                    }
                });
            });
            if (model.player.get('playerRank') == 0) {
                // default
                Ember.set(model.player, 'playerRank', parseInt(model.rank.get('length')) + 1);
            }

            // find User daily completed achievements
            var playerDailyCompleted = 0;
            model.player.get('playerTasks').forEach(function (task) {
                var taskTypeAlias = task.get('task').get('taskType').get('alias');
                if (taskTypeAlias == "QUIZ_DAILY" && task.get('points') > 0) {
                    //check success of dailyQuiz
                    ++playerDailyCompleted;
                }
                if (taskTypeAlias == 'ACTION_PROFILE') {
                    Ember.run.schedule("afterRender", this, function () {
                        Ember.$('#edit-profile').removeClass('hide');
                    });
                }
            });
            controller.set('playerDailyCompleted', playerDailyCompleted);

            // find daily total achievements for all tasks
            var playerDailyTotal = 0;
            model.tasks.forEach(function (task) {
                if (task.get('taskType').get('alias') == "QUIZ_DAILY") {
                    ++playerDailyTotal;
                }
            });
            controller.set('playerDailyTotal', playerDailyTotal);
        }
    });
});