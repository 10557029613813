define('excelixis-client/models/question', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        content: _emberData.default.attr('String'),
        helpText: _emberData.default.attr('String'),
        answers: _emberData.default.hasMany('answer', { async: false }),
        quiz: _emberData.default.belongsTo('quiz', { async: false })
    });
});