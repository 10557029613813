define('excelixis-client/models/player-task', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        player: _emberData.default.belongsTo('player', { async: false }),
        task: _emberData.default.belongsTo('task', { async: false }),
        startDate: _emberData.default.attr('date'),
        endDate: _emberData.default.attr('date'),
        points: _emberData.default.attr('number'),
        entries: _emberData.default.attr('number', { defaultValue: 0 })
    });
});