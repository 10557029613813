define('excelixis-client/controllers/register', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        API_HOST: _environment.default.APP.API_HOST,
        API_DIR: _environment.default.APP.API_DIR,
        password: '',
        registerLayout: true,
        errorMessage: 'Συμπλήρωσε σωστά τα στοιχεία σου παρακαλώ',
        valid: false,
        strong: false,
        validator: Ember.inject.service(),
        strengthObserver: Ember.observer('password', function () {
            Ember.$('.form-input-control').removeClass('red').removeClass('green-light').addClass('black');
            if (this.get('validator').hasStrongPassword(this.get('password'))) {
                this.set('strong', true);
                if (this.get('repeat') === this.get('password')) {
                    this.set('valid', true);
                } else {
                    this.set('valid', false);
                }
            } else {
                this.set('strong', false);
            }
            return;
        }),
        repeatObserver: Ember.observer('repeat', function () {
            if (this.get('repeat') === this.get('password') && this.get('strong')) {
                this.set('valid', true);
                Ember.$('.form-control').removeClass('black').removeClass('red').addClass('green-light');
            } else {
                Ember.$('.form-control').removeClass('black').removeClass('green-light').addClass('red');
                this.set('valid', false);
            }
            return;
        }),
        actions: {
            register: function register() {
                if (this.get('valid')) {
                    this.set('hasError', false);
                    if (typeof this.get('invite') !== 'undefined' && this.get('invite').length !== 0) {
                        this.send('serverCall', this.get('invite'), null, this.get('password'));
                    } else if (typeof this.get('reset') !== 'undefined' && this.get('reset').length !== 0) {
                        this.send('serverCall', null, this.get('reset'), this.get('password'));
                    } else {
                        this.set('hasError', true);
                        this.set('errorMessage', 'Λάθος παράμετρος url. Πατήστε πάλι το link ενεργοποίησης παρακαλώ');
                    }
                } else {
                    this.set('hasError', true);
                }
            },
            serverCall: function serverCall(inviteCode, resetCode, password) {
                var that = this;
                if (inviteCode) {
                    Ember.$.ajax({
                        url: this.get('API_HOST') + '/' + this.get('API_DIR') + '/api/registers/' + inviteCode,
                        type: 'PUT',
                        data: { plainPassword: password },
                        success: function success() {
                            that.set('ResultMessage', 'Το παιχνίδι ξεκινάει,<br />Ανοίξτε το e-mail που σας έχει σταλεί για να ενεργοποιηθεί ο λογαριασμός σας!');
                        },
                        error: function error() {
                            that.set('ResultMessage', 'Κάποιο σφάλμα συνέβη. <br />Προσπαθήστε πάλι αργότερα.');
                        },
                        complete: function complete() {
                            that.toggleProperty('registerLayout');
                        }
                    });
                } else if (resetCode) {
                    Ember.$.ajax({
                        url: this.get('API_HOST') + '/' + this.get('API_DIR') + '/api/passwords/' + resetCode,
                        type: 'PUT',
                        data: { plainPassword: password },
                        success: function success() {
                            that.set('ResultMessage', 'Ο κωδικός σου άλλαξε!');
                            that.transitionToRoute('login');
                        },
                        error: function error(jqXHR) {
                            var message = jqXHR.responseJSON;
                            if (message) {
                                that.set('ResultMessage', message + '<br /></br />[Κωδικός σφάλματος: ' + jqXHR.status + ']');
                            } else {
                                that.set('ResultMessage', 'Σφάλμα επικοινωνίας');
                            }
                        },
                        complete: function complete() {
                            that.toggleProperty('registerLayout');
                        }
                    });
                } else {
                    console.error('ERROR: Invalid URL parameters.');
                }
            }
        }
    });
});