define('excelixis-client/controllers/welcome-modal', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        API_HOST: _environment.default.APP.API_HOST,
        API_DIR: _environment.default.APP.API_DIR,
        init: function init() {
            this._super.apply(this, arguments);
            setTimeout(function () {
                Ember.$('#welcomeModal').css('height', Ember.$(document).height() + 'px');
            }, 300);
        },

        actions: {
            close: function close() {
                // close from parent's dashboard/index Route
                this.send('closeWelcomeModal');
            }
        }
    });
});