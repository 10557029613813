define('excelixis-client/models/task', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        alias: _emberData.default.attr('String'),
        startDate: _emberData.default.attr('date'),
        endDate: _emberData.default.attr('date'),
        points: _emberData.default.attr('number'),
        iconPath: _emberData.default.attr('String'),
        bgPath: _emberData.default.attr('String'),
        sort: _emberData.default.attr('number'),
        duration: _emberData.default.attr('number'),
        taskButtonText: _emberData.default.attr('String'),
        instructionButtonText: _emberData.default.attr('String'),
        instructions: _emberData.default.attr('String'),
        winText: _emberData.default.attr('String'),
        loseText: _emberData.default.attr('String'),
        mission: _emberData.default.belongsTo('mission', { async: false }),
        quizzes: _emberData.default.hasMany('quiz', { async: false }),
        taskType: _emberData.default.belongsTo('task-type', { async: false }),
        articles: _emberData.default.hasMany('article', { async: false }),
        isCompletedByPlayer: _emberData.default.attr('boolean', { defaultValue: false }),
        isLocked: _emberData.default.attr('boolean', { defaultValue: false })
    });
});