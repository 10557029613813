define('excelixis-client/routes/dashboard/my-missions', ['exports', 'excelixis-client/config/environment', 'ember-route-history/mixins/routes/route-history'], function (exports, _environment, _routeHistory) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_routeHistory.default, {
        isMobile: Ember.computed(function () {
            return Ember.$(window).width() < _environment.default.APP.widthThreshold;
        }),
        model: function model() {
            return this.modelFor('dashboard');
        },
        setupController: function setupController(controller, model) {
            var _this = this;

            this._super.apply(this, arguments);
            var tmpCompleted = [];
            var tmpPending = [];
            model.tasks.forEach(function (task) {
                // if(task.get('taskType').get('alias') !== 'QUIZ_DAILY'){
                if (task.get('isCompletedByPlayer')) {
                    tmpCompleted.push(task);
                } else {
                    tmpPending.push(task);
                }
                // }
            });
            controller.set('completedList', tmpCompleted);
            controller.set('PendingList', tmpPending);
            controller.set('tasksLength', tmpCompleted.length + tmpPending.length);
            controller.set('completedUserList', model.player.get('playerTasks'));
            if (this.get('isMobile')) {
                controller.set('completedTasks', tmpCompleted.slice(0, 2));
                controller.set('pendingTasks', tmpPending.slice(0, 2));
            } else {
                controller.set('completedTasks', tmpCompleted.slice(0, 4));
                controller.set('pendingTasks', tmpPending.slice(0, 4));
            }
            Ember.run.schedule('afterRender', this, function () {
                Ember.$('ol#completedPagination li a:eq(0), ol#pendingPagination li a:eq(0)').addClass('active');
                Ember.$('#PendingList .task-container .taskButtonText, #completedList .task-container .taskButtonText').remove();
                if (_this.get('isMobile')) {
                    Ember.$('.task-container .header-task-icon').remove();
                    Ember.$('.task-img-responsive-rule img').removeClass('img-responsive').css('max-height', '60px');
                }
            });
        }
    });
});