define('excelixis-client/services/task-header-info', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        displayInfo: false,
        avatarIcon: '',
        totalItems: 0,
        restItems: 0,
        completedItems: 0,
        show: function show() {
            this.set('displayInfo', true);
        },
        hide: function hide() {
            this.set('displayInfo', false);
        },
        reset: function reset() {
            this.hide();
            this.set('avatarIcon', '');
            this.set('totalItems', 0);
            this.set('completedItems', 0);
        }
    });
});