define('excelixis-client/initializers/timer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('component', 'task-multiple-qa', 'service:timer');
  }

  exports.default = {
    name: 'timer',
    initialize: initialize
  };
});