define('excelixis-client/routes/dashboard/syllabus', ['exports', 'ember-route-history/mixins/routes/route-history'], function (exports, _routeHistory) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_routeHistory.default, {
        queryParams: {
            earn: {}
        },
        model: function model() {
            return this.get('store').query('article', { inSyllabus: true });
        }
    });
});