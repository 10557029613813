define('excelixis-client/components/task-quiz-image', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        API_HOST: _environment.default.APP.API_HOST,
        API_DIR: _environment.default.APP.API_DIR,
        rootUrl: _environment.default.rootURL,
        complete: Ember.inject.service(),
        store: Ember.inject.service(),
        timer: Ember.inject.service(),
        taskHeaderInfo: Ember.inject.service(),
        appTitles: Ember.inject.service(),
        splashPage: true,
        won: false,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            var that = this;
            var quizId = parseInt(this.get('task').get('quizzes').get('firstObject').id);
            this.get('store').findRecord('quiz', quizId).then(function (response) {
                that.set('qaModel', response);
            });
            // PUT HTTP COMMAND FOR PLAYER
            var plTask = null;
            this.set('playerTasks', this.get('store').peekAll('playerTask'));
            this.get('playerTasks').forEach(function (playerTask) {
                if (playerTask.get('task') === that.get('task')) {
                    plTask = playerTask;
                }
            });
            if (plTask === null) {
                plTask = this.get('store').createRecord('playerTask', {
                    startDate: new Date(),
                    player: this.get('player'),
                    endDate: '',
                    points: '',
                    task: this.get('task')
                });
            } else {
                plTask.set('startDate', new Date());
            }
            this.set('playerTask', plTask);
            this.get('playerTask').save();
            Ember.run.schedule('afterRender', this, function () {
                _this.get('timer').stop(); // stop timer if is existed from previous task
            });
        },
        timeObserver: Ember.observer('timer.countdownDuration', function () {
            if (this.get('timer.countdownDuration') == 0) {
                var taskDescr = this.get('won') ? this.get('task.winText') : this.get('task.loseText');
                var points = this.get('won') ? this.get('task').get('points') : 0;
                this.set('popupDataCompon', {
                    isActive: true,
                    taskId: this.get('task.id'),
                    taskAlias: this.get('task.alias'),
                    taskDescr: taskDescr,
                    taskDescr2: '',
                    taskPoints: points,
                    won: this.get('won')
                }); // Parent's Modal Controller set property
                this.get('complete').set('gamePoints', points); // before completedTaskId must be
                this.get('complete').set('completedTaskId', this.get('task').get('id')); // Notify Task completion
                // PUT REQUEST
                this.get('playerTask').set('endDate', new Date());
                this.get('playerTask').set('points', points);
            }
            this.get('playerTask').save();
        }),
        actions: {
            startPage: function startPage() {
                var _this2 = this;

                this.set('splashPage', false);
                // IMAGE LOADER
                this.get('complete').startImageLoader();
                Ember.run.schedule('afterRender', this, function () {
                    var that = _this2;
                    // When all images have been loaded DO . . .
                    $('#taskModal').imagesLoaded({ background: true }, function () {
                        that.get('complete').stopImageLoader();
                        that.get('timer').start(that.get('task').get('duration')); // Dynamic task duration
                    });
                    _this2.get('taskHeaderInfo').show();
                    _this2.get('taskHeaderInfo').set('totalItems', '-');
                    _this2.get('taskHeaderInfo').set('completedItems', '-');
                    _this2.get('taskHeaderInfo').set('restItems', '-');
                    // this.get('timer').start(this.get('task').get('duration')); // EINAI PANOY^
                });
                // More than 2 times play = Game Over my man!
                this.get('playerTask').set('entries', this.get('playerTask').get('entries') + 1);
                if (this.get('playerTask').get('entries') > 1) {
                    this.get('complete').set('gamePoints', 0); // before completedTaskId must be
                    this.get('complete').set('completedTaskId', this.get('task').get('id') + this.get('appTitles').get('againSubfix')); // Notify Task completion
                    // PUT REQUEST
                    this.get('playerTask').set('endDate', new Date());
                    this.get('playerTask').set('points', 0);
                    this.get('playerTask').save();
                }
            },
            answer: function answer(index) {
                this.send('result', index);
            },
            result: function result(indexQA) {
                this.get('timer').pause();
                var that = this;
                var answers = this.get('qaModel').get('questions').get('firstObject').get('answers');
                Ember.$('#taskModal #logoAnswers > ul li img').addClass('disable-click');
                answers.forEach(function (answer, index) {
                    if (answer.get('isCorrect')) {
                        Ember.$('#taskModal #logoAnswers > ul li img:eq(' + index + ')').addClass('image-correct');
                        if (indexQA == index) {
                            that.set('won', true);
                        }
                    } else {
                        if (indexQA == index) {
                            Ember.$('#taskModal #logoAnswers > ul li img:eq(' + index + ')').addClass('image-fail');
                        }
                    }
                });
                var points = this.get('won') ? this.get('task').get('points') : 0;
                var taskDescr = this.get('won') ? this.get('task.winText') : this.get('task.loseText');
                this.get('complete').set('gamePoints', points); // before completedTaskId must be
                this.get('complete').set('completedTaskId', this.get('task').get('id')); // Notify Task completion
                setTimeout(function () {
                    that.set('popupDataCompon', {
                        isActive: true,
                        taskId: that.get('task.id'),
                        taskAlias: that.get('task.alias'),
                        taskDescr: taskDescr,
                        taskDescr2: '',
                        taskPoints: points,
                        won: that.get('won')
                    }); // Parent's Modal Controller set property
                }, 3000);
                // PUT REQUEST
                this.get('playerTask').set('endDate', new Date());
                this.get('playerTask').set('points', points);
                this.get('playerTask').save();
            }
        }
    });
});