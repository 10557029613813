define('excelixis-client/helpers/progress-compute', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.progressCompute = progressCompute;
    function progressCompute(params /*, hash*/) {
        var value = params[0];
        var max = params[1];
        if (value >= 0 && value <= max) {
            return Ember.String.htmlSafe(Math.round(value / max * 100));
        }
        return 0;
    }

    exports.default = Ember.Helper.helper(progressCompute);
});