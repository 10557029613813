define('excelixis-client/services/route-history', ['exports', 'ember-route-history/services/route-history'], function (exports, _routeHistory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _routeHistory.default;
    }
  });
});