define('excelixis-client/components/daily-quiz-template', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        API_HOST: _environment.default.APP.API_HOST,
        API_DIR: _environment.default.APP.API_DIR,
        store: Ember.inject.service(),
        timer: Ember.inject.service(),
        complete: Ember.inject.service(),
        appTitles: Ember.inject.service(),
        DailyTemplate: true,
        won: false,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            // IMAGE LOADER
            this.get('complete').startImageLoader();
            Ember.run.schedule('afterRender', this, function () {
                var that = _this;
                // IMAGE LOADER END
                $('#dailyQuizModal #dailyBg').imagesLoaded({ background: true }, function () {
                    that.get('complete').stopImageLoader();
                    that.get('timer').start(that.get('model').get('duration'));
                });
                _this.set('DailyTemplateComp', true);
                var store = _this.get('store');
                // PUT HTTP COMMAND FOR PLAYER
                var plTask = null;
                _this.set('playerTasks', store.peekAll('playerTask'));
                _this.get('playerTasks').forEach(function (playerTask) {
                    if (playerTask.get('task') === that.get('model')) {
                        plTask = playerTask;
                    }
                });
                if (plTask === null) {
                    plTask = store.createRecord('playerTask', {
                        startDate: new Date(),
                        player: _this.get('player'),
                        endDate: '',
                        points: '',
                        task: _this.get('model')
                    });
                } else {
                    plTask.set('startDate', new Date());
                }
                _this.set('playerTask', plTask);
                // More than 2 times play = Game Over my man!
                _this.get('playerTask').set('entries', _this.get('playerTask').get('entries') + 1);
                if (_this.get('playerTask').get('entries') > 1) {
                    _this.get('complete').set('gamePoints', 0); // before completedTaskId must be
                    _this.get('complete').set('completedTaskId', _this.get('model').get('id') + _this.get('appTitles').get('againSubfix')); // Notify Task completion
                    // PUT REQUEST
                    _this.get('playerTask').set('endDate', new Date());
                    _this.get('playerTask').set('points', 0);
                }
                _this.get('playerTask').save();
                /* HELPS */
                _this.get('player').get('playerHelps').forEach(function (playerHelp) {
                    switch (playerHelp.get('alias')) {
                        case 'NEXT':
                            Ember.$('#dailyQuizModal .help:eq(0)').addClass('disable');
                            break;
                        case 'TIP':
                            Ember.$('#dailyQuizModal .help:eq(1)').addClass('disable');
                            break;
                        case 'FIFTY':
                            Ember.$('#dailyQuizModal .help:eq(2)').addClass('disable');
                            break;
                    }
                });
                Ember.$('#dailyQuizModal .help img').hover(function () {
                    // hover handlerIn
                    Ember.$(this).parent().addClass('hover-help');
                    Ember.$(this).next().css('display', 'inline-block');
                }, function () {
                    // hover handlerOut
                    Ember.$(this).parent().removeClass('hover-help');
                    Ember.$(this).next().css('display', 'none');
                });
                if (_this.get('isMobile')) {
                    Ember.$('#dailyQuizModal .help span').css('display', 'inline-flex');
                }
                if (_this.get('player').get('playerHelps').length == 3) {
                    _this.set('noHelp', true); // Change header title message
                }

                var quizId = parseInt(_this.get('model').get('quizzes').get('firstObject').get('id'));
                store.findRecord('quiz', quizId).then(function (quiz) {
                    that.set('quiz', quiz);
                });
            });
        },

        timeObserver: Ember.observer('timer.countdownDuration', function () {
            if (this.get('timer.countdownDuration') == 0) {
                this.send('result', -1);
            }
        }),
        actions: {
            answer: function answer(index) {
                Ember.$('#dailyQuizModal .answer-style').removeClass('selected');
                Ember.$('#dailyQuizModal .answer-style:eq(' + index + ')').addClass('selected');
                Ember.$('#dailyQuizModal .answer-style, #dailyQuizModal .help').addClass('disable-click');
                this.send('result', index);
            },
            result: function result(indexQa) {
                var that = this;
                this.get('timer').pause();
                setTimeout(function () {
                    var answers = that.get('quiz').get('questions').get('firstObject').get('answers');
                    if (indexQa == -1) {// time expired
                    } else if (answers.objectAt(indexQa).get('isCorrect')) {
                        Ember.$('#dailyQuizModal .answer-style:eq(' + indexQa + ')').addClass('correct');
                        that.set('won', true);
                    } else {
                        Ember.$('#dailyQuizModal .answer-style:eq(' + indexQa + ')').addClass('fail');
                        answers.forEach(function (answer, index) {
                            if (answer.get('isCorrect')) {
                                Ember.$('#dailyQuizModal .answer-style:eq(' + index + ')').addClass('correct');
                            }
                        });
                    }
                    var points = that.get('won') ? that.get('model.points') : 0;
                    // PUT REQUEST
                    that.get('playerTask').set('endDate', new Date());
                    that.get('playerTask').set('points', points);
                    that.get('playerTask').save();
                    // after playerTask Save
                    that.get('complete').set('gamePoints', points); // before completedTaskId must be
                    that.get('complete').set('completedTaskId', that.get('model').get('id')); // Notify Task completion
                    setTimeout(function () {
                        that.set('DailyTemplate', false);
                        that.get('timer').stop(); // stop timer
                        that.set('DailyTemplateComp', false);
                    }, 3000);
                }, 1000);
            },
            help: function help(index) {
                Ember.$('#dailyQuizModal .help:eq(' + index + ')').addClass('disable');
                var playerHelp = this.get('store').createRecord('playerHelp', {
                    player: this.get('player'),
                    task: this.get('model'),
                    createdAt: new Date()
                });
                switch (index) {
                    case 0:
                        {
                            // Change question
                            this.get('timer').stop(); // stop timer
                            this.get('timer').start(this.get('model').get('duration')); // stop timer
                            var that = this;
                            this.set('showHelp', false);
                            var store = this.get('store');
                            store.query('quiz', { task: false }).then(function (resp) {
                                that.set('quiz', resp.get('firstObject'));
                            });
                            playerHelp.set('alias', 'NEXT');
                            break;
                        }
                    case 1:
                        // helpText
                        this.toggleProperty('showHelp');
                        playerHelp.set('alias', 'TIP');
                        break;
                    case 2:
                        {
                            // 50 - 50
                            var indexArray = [];
                            var answers = this.get('quiz').get('questions').get('firstObject').get('answers');
                            answers.forEach(function (answer, index) {
                                if (!answer.get('isCorrect')) {
                                    indexArray.push(index);
                                }
                            });
                            var firstIndex = Math.floor(Math.random() * indexArray.length + 0);
                            Ember.$('#dailyQuizModal .answer:eq(' + indexArray[firstIndex] + ')').addClass('disable');
                            indexArray.splice(firstIndex, 1);
                            var secondIndex = Math.floor(Math.random() * indexArray.length + 0);
                            Ember.$('#dailyQuizModal .answer:eq(' + indexArray[secondIndex] + ')').addClass('disable');
                            playerHelp.set('alias', 'FIFTY');
                            break;
                        }
                }
                playerHelp.save();
            }
        }
    });
});