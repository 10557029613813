define('excelixis-client/services/timer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        time: { string: {} },
        willDestroy: function willDestroy() {
            this.stop();
            this._super();
        },
        start: function start(seconds) {
            this.set('countdownDuration', seconds);
            this.set('totalTime', seconds);
            var self = this;
            this.interval = setInterval(function () {
                Ember.run(self, 'countdown');
            }, 1000);
        },

        countdown: function countdown() {
            var momentTime = moment.duration(this.get('countdownDuration') - 1, 'seconds');
            this.set('time.minutes', momentTime.minutes());
            this.set('time.seconds', momentTime.seconds());
            this.set('time.string.minutes', momentTime.minutes() < 10 ? "0" + momentTime.minutes().toString() : momentTime.minutes());
            this.set('time.string.seconds', momentTime.seconds() < 10 ? "0" + momentTime.seconds().toString() : momentTime.seconds());
            this.set('countdownDuration', this.get('countdownDuration') - 1);
            this.set('progress', this.get('totalTime') - this.get('countdownDuration'));
            if (this.get('countdownDuration') == 0) {
                this.stop();
            }
        },
        pause: function pause() {
            if (this.interval) {
                clearInterval(this.interval);
            }
        },
        resume: function resume() {
            this.start(this.get('countdownDuration'));
        },
        stop: function stop() {
            this.pause();
            this.set('progress', 0);
            this.set('time.minutes', 0);
            this.set('time.seconds', 0);
            this.set('time.string.minutes', "");
            this.set('time.string.seconds', "");
        }
    });
});