define('excelixis-client/routes/login', ['exports', 'ember-route-history/mixins/routes/route-history'], function (exports, _routeHistory) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_routeHistory.default, {
        session: Ember.inject.service(),
        setupController: function setupController() {
            this._super.apply(this, arguments);
            var authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
            try {
                if (authenticator.getTokenData(this.get('session').get('data.authenticated.token'))) {
                    var previousRoute = this.get('routeHistory.previous');
                    if (previousRoute !== null && (previousRoute.indexOf('dashboard') !== -1 || previousRoute.indexOf('login') !== -1)) {
                        this.transitionTo('dashboard');
                    }
                }
            } catch (e) {
                console.error("Unauth User error", e);
            }
        }
    });
});