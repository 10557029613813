define('excelixis-client/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.transition(this.fromRoute('dashboard.index'), this.toRoute('dashboard.my_missions'), this.toRoute('dashboard.leaderboard'), this.toRoute('dashboard.syllabus'), this.toRoute('dashboard.prizes'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('dashboard.my_missions'), this.toRoute('dashboard.index'), this.toRoute('dashboard.leaderboard'), this.toRoute('dashboard.syllabus'), this.toRoute('dashboard.prizes'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('dashboard.leaderboard'), this.toRoute('dashboard.index'), this.toRoute('dashboard.my_missions'), this.toRoute('dashboard.syllabus'), this.toRoute('dashboard.prizes'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('dashboard.syllabus'), this.toRoute('dashboard.index'), this.toRoute('dashboard.my_missions'), this.toRoute('dashboard.leaderboard'), this.toRoute('dashboard.prizes'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('dashboard.prizes'), this.toRoute('dashboard.index'), this.toRoute('dashboard.my_missions'), this.toRoute('dashboard.leaderboard'), this.toRoute('dashboard.syllabus'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('dashboard.index'), this.toRoute('dashboard.player-info'), this.use('toDown'), this.reverse('toUp'));
    this.transition(this.fromRoute('dashboard.player-info'), this.toRoute('dashboard.my_missions'), this.toRoute('dashboard.leaderboard'), this.toRoute('dashboard.syllabus'), this.toRoute('dashboard.prizes'), this.use('toUp'), this.reverse('toDown'));

    this.transition(this.hasClass('dailyQuiz'), this.toValue(true), this.use('toRight'), this.reverse('toLeft', { duration: 300 }));
  };
});