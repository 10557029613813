define('excelixis-client/controllers/dashboard/prizes', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        isMobile: Ember.computed(function () {
            return Ember.$(window).width() < _environment.default.APP.widthThreshold ? true : false;
        }),
        actions: {
            goHome: function goHome() {
                this.transitionToRoute('dashboard.index');
            }
        }
    });
});