define('excelixis-client/helpers/is-earn', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEarn = isEarn;
  function isEarn(params /*, hash*/) {
    return params[0] > -1;
  }

  exports.default = Ember.Helper.helper(isEarn);
});