define('excelixis-client/routes/register', ['exports', 'ember-route-history/mixins/routes/route-history'], function (exports, _routeHistory) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_routeHistory.default, {
        queryParams: {
            invite: {},
            reset: {}
        },
        setupController: function setupController(controller) {
            this._super.apply(this, arguments);
            if (controller.invite && controller.reset || !controller.invite && !controller.reset) {
                this.transitionTo('error');
            }
        }
    });
});