define('excelixis-client/helpers/num-to-alpha', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.numToAlpha = numToAlpha;
    function numToAlpha(params /*, hash*/) {
        switch (params[0]) {
            case 0:
                return Ember.String.htmlSafe('Α');
            case 1:
                return Ember.String.htmlSafe('Β');
            case 2:
                return Ember.String.htmlSafe('Γ');
            case 3:
                return Ember.String.htmlSafe('Δ');
            case 4:
                return Ember.String.htmlSafe('Ε');
            case 5:
                return Ember.String.htmlSafe('ΣΤ');
            default:
                return Ember.String.htmlSafe(params[0]);
        }
    }

    exports.default = Ember.Helper.helper(numToAlpha);
});