define('excelixis-client/components/syllabus-topic', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        extended: false,
        content: Ember.computed(function () {
            var content = Ember.$('<textarea />').html(this.get('topic').get('content')).text();
            if (this.get('topic').get('url') !== undefined) {
                content += "<br /><br />Περισσότερα <a href=\"" + this.get('topic').get('url') + "\"" + " target=\"_blank\">εδώ.</a>";
            }
            return content;
        }),
        actions: {
            toggle: function toggle() {
                this.toggleProperty('extended');
            }
        }
    });
});