define('excelixis-client/routes/dashboard/player-info', ['exports', 'ember-route-history/mixins/routes/route-history'], function (exports, _routeHistory) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_routeHistory.default, {
        queryParams: {
            earn: {}
        },
        model: function model() {
            return Ember.RSVP.hash({
                shops: this.store.findAll('store'),
                jobs: this.store.findAll('jobPosition'),
                avatars: this.store.findAll('avatar'),
                player: this.modelFor('dashboard').player,
                tasks: this.modelFor('dashboard').tasks
            });
        },
        setupController: function setupController(controller, model) {
            this._super.apply(this, arguments);
            if (controller.earn <= -1) {
                Ember.run.schedule("afterRender", this, function () {
                    Ember.$('#submit-button').removeClass('disable');
                });
            }
            controller.set('firstName', model.player.get('firstName'));
            controller.set('lastName', model.player.get('lastName'));
            controller.set('shop', model.player.get('shop'));
            controller.set('jobPosition', model.player.get('jobPosition'));
            controller.set('avatar', model.player.get('avatar'));

            controller.set('hasFirst', model.player.get('firstName') !== null);
            controller.set('hasLast', model.player.get('lastName') !== null);
            controller.set('hasAvatar', model.player.get('avatar') !== null);
            controller.set('hasStore', model.player.get('shop') !== null);
            controller.set('hasJob', model.player.get('jobPosition') !== null);
            controller.set('isUploadImageTempl', false);
            controller.set('croppedAvatar', null);
        }
    });
});