define('excelixis-client/models/player-help', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        alias: _emberData.default.attr('string'),
        createdAt: _emberData.default.attr('date'),
        player: _emberData.default.belongsTo('player', { async: false }),
        task: _emberData.default.belongsTo('task', { async: false })
    });
});