define('excelixis-client/router', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend({
        location: _environment.default.locationType,
        rootURL: _environment.default.rootURL
    });

    Router.map(function () {
        this.route('login');
        this.route('register');
        this.route('dashboard', function () {
            this.route('my_missions');
            this.route('leaderboard');
            this.route('syllabus');
            this.route('prizes');
            this.route('player-info');
        });
        this.route('error');
        this.route('invalid_url', { path: '/*' });
    });

    exports.default = Router;
});