define('excelixis-client/controllers/login', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        API_HOST: _environment.default.APP.API_HOST,
        API_DIR: _environment.default.APP.API_DIR,
        session: Ember.inject.service(),
        validator: Ember.inject.service(),
        complete: Ember.inject.service(),
        identification: null,
        password: null,
        isLoginTemplate: true,
        isSubmitEmailTemplate: false,
        actions: {
            authenticate: function authenticate() {
                this.set('errorMessage', '');
                var mail = this.get('identification');
                if (!this.get('validator').isSet(mail, this.get('password')) || !this.get('validator').isSet(this.get('password')) || !this.get('validator').isFulfilled(mail) || !this.get('validator').isFulfilled(this.get('password'))) {
                    this.set('errorMessage', 'Συμπληρώστε email και password παρακαλώ');
                    return;
                }
                if (!this.get('validator').isEmail(mail)) {
                    this.set('errorMessage', 'Συμπληρώστε το email σας παρακαλώ');
                    return;
                }
                var credentials = this.getProperties('identification', 'password');
                var that = this;
                this.get('session').authenticate('authenticator:jwt', credentials).then(function () /*_session*/{
                    that.transitionToRoute('dashboard');
                }, function (error) {
                    switch (error.code) {
                        case 401:
                            that.set('errorMessage', 'Λάθος στοιχεία');
                            break;
                        case 500:
                            that.set('errorMessage', 'Σφάλμα διακομιστή');
                            break;
                        default:
                            that.set('errorMessage', 'Σφάλμα');
                    }
                });
            },
            forgotPassword: function forgotPassword() {
                this.set("errorMessage", null);
                this.set("isLoginTemplate", false);
                this.set("isSubmitEmailTemplate", true);
            },
            submitEmail: function submitEmail() {
                var mail = this.get('forgottenEmail');
                if (!this.get('validator').isSet(mail) || !this.get('validator').isFulfilled(mail) || !this.get('validator').isEmail(mail)) {
                    this.set('errorMessage', 'Συμπληρώστε το email σας παρακαλώ');
                    return;
                }
                this.set('errorMessage', null);
                this.get('complete').startImageLoader();
                var that = this;
                var successHandler = function successHandler() {
                    that.set('errorMessage', null);
                    that.set("isSubmitEmailTemplate", false);
                };
                var errorHandler = function errorHandler(jqXHR) {
                    var message = jqXHR.responseJSON;
                    if (message) {
                        that.set('errorMessage', message + '<br/><br/>[Κωδικός σφάλματος: ' + jqXHR.status + ']');
                    } else {
                        that.set('errorMessage', 'Κάποιο σφάλμα συνέβη. <br />Προσπαθήστε πάλι αργότερα.');
                    }
                };
                var finalHandler = function finalHandler() {
                    that.get('complete').stopImageLoader();
                };
                Ember.$.get(this.get('API_HOST') + '/' + this.get('API_DIR') + '/api/passwords/' + mail, successHandler).fail(errorHandler).always(finalHandler);
            }
        }
    });
});