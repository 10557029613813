define('excelixis-client/helpers/nl-to-br', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.nlToBr = nlToBr;
    function nlToBr(params /*, hash*/) {
        var text = params[0].replace(/(\r\n|\n|\r)/gm, '<br>');
        return Ember.String.htmlSafe(text);
    }

    exports.default = Ember.Helper.helper(nlToBr);
});