define('excelixis-client/routes/application', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        actions: {
            error: function error(_error) {
                // auto triggered function
                console.log("error >", _error);
                try {
                    if (_error.errors.get('firstObject').status == '401') {
                        this.transitionTo('login');
                    } else {
                        this.transitionTo('error');
                    }
                } catch (e) {
                    console.log("catch >", e);
                    this.transitionTo('error');
                }
                return true;
            }
        }
    });
});