define('excelixis-client/components/task-wheel', ['exports', 'excelixis-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        API_HOST: _environment.default.APP.API_HOST,
        API_DIR: _environment.default.APP.API_DIR,
        complete: Ember.inject.service(),
        store: Ember.inject.service(),
        appTitles: Ember.inject.service(),
        playedOnce: false,
        splashPage: true,
        init: function init() {
            this._super.apply(this, arguments);
            // PUT HTTP COMMAND FOR PLAYER
            var that = this;
            var plTask = null;
            this.set('playerTasks', this.get('store').peekAll('playerTask'));
            this.get('playerTasks').forEach(function (playerTask) {
                if (playerTask.get('task') === that.get('task')) {
                    plTask = playerTask;
                }
            });
            if (plTask === null) {
                plTask = this.get('store').createRecord('playerTask', {
                    startDate: new Date(),
                    player: this.get('player'),
                    endDate: '',
                    points: '',
                    task: this.get('task')
                });
            } else {
                plTask.set('startDate', new Date());
            }
            this.set('playerTask', plTask);
            this.get('playerTask').save();
        },
        willDestroyElement: function willDestroyElement() {
            clearTimeout(window.wheelTimeOut);
        },

        timeObserver: Ember.observer('splashPage', function () {
            var _this = this;

            if (!this.get('splashPage')) {
                Ember.run.schedule("afterRender", this, function () {
                    _this.set('points', 0);
                    /* Author: V. Giannop */
                    _this.set('again', 0);
                    document.body.addEventListener('touchmove', function (event) {
                        event.preventDefault();
                    }, false);
                });
            }
        }),
        actions: {
            startPage: function startPage() {
                var _this2 = this;

                this.set('splashPage', false);
                // More than 2 times play = Game Over my man!
                this.get('playerTask').set('entries', this.get('playerTask').get('entries') + 1);
                if (this.get('playerTask').get('entries') > 1) {
                    this.get('complete').set('gamePoints', 0); // before completedTaskId must be
                    this.get('complete').set('completedTaskId', this.get('task').get('id') + this.get('appTitles').get('againSubfix')); // Notify Task completion
                    // PUT REQUEST
                    this.get('playerTask').set('endDate', new Date());
                    this.get('playerTask').set('points', 0);
                }
                this.get('playerTask').save();
                // IMAGE LOADER
                this.get('complete').startImageLoader();
                Ember.run.schedule('afterRender', this, function () {
                    var that = _this2;
                    // When all images have been loaded DO . . .
                    $('#taskModal').imagesLoaded({ background: true }, function () {
                        that.get('complete').stopImageLoader();
                    });
                });
            },
            rotate: function rotate() {
                var that = this;
                if (!this.get('playedOnce')) {
                    $('#game').prop('onclick', null).off('click');
                    var rand = Math.floor(Math.random() * 100 + 1);
                    if (rand <= 12) {
                        $('#spin').css('-webkit-transform', 'rotate(3640deg)');
                        $('#spin').css('-ms-transform', 'rotate(3640deg)');
                        $('#spin').css('-moz-transform', 'rotate(3640deg)');
                        $('#spin').css('transform', 'rotate(3640deg)');
                        this.set('points', 5);
                    }
                    if (rand > 12 && rand <= 25) {
                        $('#spin').css('-webkit-transform', 'rotate(3780deg)');
                        $('#spin').css('-ms-transform', 'rotate(3780deg)');
                        $('#spin').css('-moz-transform', 'rotate(3780deg)');
                        $('#spin').css('transform', 'rotate(3780deg)');
                        this.set('points', 20);
                    }
                    if (rand > 25 && rand <= 37) {
                        //play again
                        $('#spin').css('-webkit-transform', 'rotate(3720deg)');
                        $('#spin').css('-ms-transform', 'rotate(3720deg)');
                        $('#spin').css('-moz-transform', 'rotate(3720deg)');
                        $('#spin').css('transform', 'rotate(3720deg)');
                        this.set('points', 0);
                    }
                    if (rand > 37 && rand <= 50) {
                        $('#spin').css('-webkit-transform', 'rotate(3910deg)');
                        $('#spin').css('-ms-transform', 'rotate(3910deg)');
                        $('#spin').css('-moz-transform', 'rotate(3910deg)');
                        $('#spin').css('transform', 'rotate(3910deg)');
                        this.set('points', 5);
                    }
                    if (rand >= 50 && rand <= 62) {
                        $('#spin').css('-webkit-transform', 'rotate(3820deg)');
                        $('#spin').css('-ms-transform', 'rotate(3820deg)');
                        $('#spin').css('-moz-transform', 'rotate(3820deg)');
                        $('#spin').css('transform', 'rotate(3820deg)');
                        this.set('points', 5);
                    }
                    if (rand > 62 && rand <= 75) {
                        $('#spin').css('-webkit-transform', 'rotate(3960deg)');
                        $('#spin').css('-ms-transform', 'rotate(3960deg)');
                        $('#spin').css('-moz-transform', 'rotate(3960deg)');
                        $('#spin').css('transform', 'rotate(3960deg)');
                        this.set('points', 20);
                    }
                    if (rand > 75 && rand <= 87) {
                        $('#spin').css('-webkit-transform', 'rotate(3690deg)');
                        $('#spin').css('-ms-transform', 'rotate(3690deg)');
                        $('#spin').css('-moz-transform', 'rotate(3690deg)');
                        $('#spin').css('transform', 'rotate(3690deg)');
                        this.set('points', 10);
                    }
                    if (rand > 87) {
                        $('#spin').css('-webkit-transform', 'rotate(3870deg)');
                        $('#spin').css('-ms-transform', 'rotate(3870deg)');
                        $('#spin').css('-moz-transform', 'rotate(3870deg)');
                        $('#spin').css('transform', 'rotate(3870deg)');
                        this.set('points', 10);
                    }
                    this.toggleProperty('playedOnce');

                    window.wheelTimeOut = setTimeout(function () {
                        // RESULT
                        if (that.get('again') == 1) {
                            that.set('points', 20); // (?)
                        }
                        if (that.get('points') > 0) {
                            that.send('finish');
                        } else {
                            Ember.$("#game").click(function () {
                                Ember.$('#spin').css('transform', 'none');
                                that.set('again', 1);
                                clearTimeout(window.wheelTimeOut);
                                window.wheelTimeOut = setTimeout(function () {
                                    that.set('points', 20); // after Again choice, always get 20 points
                                    that.send('finish');
                                }, 11 * 1000);
                            });
                        }
                    }, 11 * 1000);
                }
            },
            finish: function finish() {
                this.get('complete').set('gamePoints', this.get('points')); // before completedTaskId must be
                this.get('complete').set('completedTaskId', this.get('task').get('id')); // Notify Task completion
                this.set('pointsDisplay', 'Κέρδισες ' + this.get('points') + ' πόντους!');
                this.set('popupDataCompon', {
                    isActive: true,
                    taskId: this.get('task.id'),
                    taskAlias: this.get('task.alias'),
                    taskDescr: this.get('task.winText'),
                    taskDescr2: '',
                    taskPoints: this.get('points'),
                    won: true
                }); // Parent's Modal Controller set property
                // PUT REQUEST
                this.get('playerTask').set('endDate', new Date());
                this.get('playerTask').set('points', this.get('points'));
                this.get('playerTask').save();
            }
        }
    });
});