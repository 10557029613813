define('excelixis-client/services/validator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        emailRegex: /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i,
        allCharactersRegex: /^[a-zA-Zα-ωΑ-ΩάέήίόύώΆΈΉΊΌΎΏ]+$/i,
        passwordRegex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$/,
        isSet: function isSet(input) {
            return !(input == null);
        },
        isFulfilled: function isFulfilled(input) {
            return !(input.toString().trim().length === 0);
        },
        isEmail: function isEmail(input) {
            return this.get('emailRegex').test(input.toString().trim());
        },
        isAlpha: function isAlpha(input) {
            return this.get('allCharactersRegex').test(input.toString().trim());
        },
        hasStrongPassword: function hasStrongPassword(password) {
            return this.get('passwordRegex').test(password.toString());
        }
    });
});