define('excelixis-client/app', ['exports', 'excelixis-client/resolver', 'ember-load-initializers', 'excelixis-client/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var App = void 0;

  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  Ember.$(document).keyup(function (e) {
    if (e.keyCode === 37) Ember.$('.glyphicon-menu-left').trigger('click'); // left Arrow
    if (e.keyCode === 39) Ember.$('.glyphicon-menu-right').trigger('click'); // right Arrow
    if (e.keyCode === 27) Ember.$('.glyphicon-home,.glyphicon-remove').trigger('click'); // esc
  });

  exports.default = App;
});