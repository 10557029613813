define('excelixis-client/routes/dashboard/index', ['exports', 'excelixis-client/config/environment', 'ember-route-history/mixins/routes/route-history'], function (exports, _environment, _routeHistory) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_routeHistory.default, {
        timer: Ember.inject.service(),
        appTitles: Ember.inject.service(),
        complete: Ember.inject.service(),
        firstTimeIntoSession: true,
        dailyTaskTypeAlias: 'QUIZ_DAILY',
        isMobile: Ember.computed(function () {
            return Ember.$(window).width() < _environment.default.APP.widthThreshold;
        }),
        model: function model() {
            return this.modelFor('dashboard');
        },
        setupController: function setupController(controller, model) {
            var _this = this;

            this._super(controller, model);
            if (!this.get('complete').get('gameIsActive')) {
                // MUST NOT BE ACTIVE A GAME
                var that = this;
                var completedLastIndex = -1;

                var _loop = function _loop(i) {
                    // Check if player has completed/finished this game of total Games.
                    model.player.get('playerTasks').forEach(function (playerTask) {
                        if (playerTask.get('task').get('id') == model.tasks.content[i].id && playerTask.get('endDate') !== undefined && playerTask.get('endDate') !== '' && playerTask.get('entries') > 0) {
                            model.tasks.objectAt(i).set('isCompletedByPlayer', true);
                        }
                    });
                    if (model.tasks.objectAt(i).get('isCompletedByPlayer')) {
                        completedLastIndex = i;
                    }
                };

                for (var i = 0; i < model.tasks.content.length; i++) {
                    _loop(i);
                }
                model.tasks.forEach(function (task, i) {
                    // MARK AS LOCKED TASKS
                    if (task.get('taskType').get('alias') == that.get('dailyTaskTypeAlias')) {
                        // FOR DAILY QUIZZES
                        var diffFromStart = moment().diff(task.get('startDate'), 'days', true);
                        var diffFromEnd = moment().diff(task.get('endDate'), 'days', true);
                        if (i > completedLastIndex + 1 || diffFromStart < 0 || diffFromEnd > 0) {
                            //same check at Controller
                            task.set('isLocked', true);
                        }
                    } else {
                        // FOR TASKS
                        if (i > completedLastIndex + 1) {
                            task.set('isLocked', true);
                        }
                    }
                });
                var tmp = [];
                model.tasks.forEach(function (task) {
                    // sort model Array based on 'sort' property
                    tmp[task.get('sort')] = task;
                });
                Ember.set(model.tasks, '', tmp); // re-order tasks based on sort attribute
                Ember.run.schedule("afterRender", this, function () {
                    // set the correct active pagination
                    if (controller.get('pageSelected') !== undefined) {
                        Ember.$('ol#pagination li a').removeClass('active');
                        Ember.$('ol#pagination li a:eq(' + controller.get('pageSelected') + ')').addClass('active');
                    } else {
                        Ember.$('ol#pagination li a:eq(0)').addClass('active');
                    }
                    // current Available unLocked task
                    Ember.$('ol#pagination li a').removeClass('indexed');
                    var firstAvailable = completedLastIndex + 1;
                    var firstAvailablePager = _this.get('isMobile') ? firstAvailable : firstAvailable / 4;
                    Ember.$('ol#pagination li a:eq(' + Math.floor(firstAvailablePager).toString() + ')').addClass('indexed').attr('title', _this.get('appTitles').get('continueHover')).trigger('click');
                    // WELCOME POP-UP TUTORIAL
                    if (model.player.get('playerTasks').length === 0 && _this.get('firstTimeIntoSession') && !_this.get('isMobile')) {
                        // no mobile display
                        that.render('welcomeModal', {
                            into: 'application',
                            outlet: 'welcomeOutlet',
                            controller: 'welcomeModal'
                        });
                        _this.toggleProperty('firstTimeIntoSession');
                    }
                });
            }
        },
        actions: {
            OpenTaskModal: function OpenTaskModal(id, type) {
                this.get('timer').stop(); // reset timer if need be mothafucka!
                var that = this;
                this.get('store').findRecord('task', id).then(function (response) {
                    if (response.get('taskType').get('alias') == 'ACTION_PROFILE') {
                        that.transitionTo('dashboard.player-info', { queryParams: { earn: response.get('points') } });
                        return;
                    } else if (response.get('taskType').get('alias') == 'ACTION_LIBRARY') {
                        that.transitionTo('dashboard.syllabus', { queryParams: { earn: response.get('points') } });
                        return;
                    } else {
                        // DAILY QUIZ OUTLET
                        Ember.set(response, 'isTask', !(type == that.get('dailyTaskTypeAlias')));
                        Ember.set(response, 'dashboardPlayerModel', that.modelFor('dashboard').player);
                        that.render('taskmodal', {
                            into: 'application',
                            outlet: 'taskOutlet',
                            controller: 'taskmodal',
                            model: response
                        });
                        if (that.get('isMobile')) {
                            Ember.run.schedule("afterRender", that, function () {
                                Ember.$('body').animate({ "scrollTop": "160px" });
                            });
                        }
                        if (response.get('isTask')) {
                            // IMAGE LOADER
                            that.get('complete').startImageLoader();
                            Ember.run.schedule('afterRender', that, function () {
                                $('#taskModal').imagesLoaded({ background: true }, function () {
                                    that.get('complete').stopImageLoader();
                                });
                            });
                        }
                    }
                });
            },
            closeTaskModal: function closeTaskModal() {
                return this.disconnectOutlet({
                    outlet: 'taskOutlet',
                    parentView: 'application'
                });
            },
            closeWelcomeModal: function closeWelcomeModal() {
                return this.disconnectOutlet({
                    outlet: 'welcomeOutlet',
                    parentView: 'application'
                });
            }
        }
    });
});