define('excelixis-client/models/article', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        alias: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        title: _emberData.default.attr('string'),
        content: _emberData.default.attr('string'),
        url: _emberData.default.attr('string'),
        inSyllabus: _emberData.default.attr('boolean'),
        task: _emberData.default.belongsTo('task', { async: false })
    });
});