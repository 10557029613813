define('excelixis-client/models/player', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        firstName: _emberData.default.attr('String'),
        lastName: _emberData.default.attr('String'),
        email: _emberData.default.attr('String'),
        shop: _emberData.default.belongsTo('store', { async: false }),
        jobPosition: _emberData.default.belongsTo('job-position', { async: false }),
        avatar: _emberData.default.belongsTo('avatar', { async: false }),
        playerTasks: _emberData.default.hasMany('player-task', { async: false }),
        totalScore: _emberData.default.attr('Number'),
        tasksCompleted: Ember.computed(function () {
            var completedTasks = 0;
            this.get('playerTasks').forEach(function (playerTask) {
                if (playerTask.get('endDate') !== undefined) {
                    ++completedTasks;
                }
            });
            return completedTasks;
        }),
        totalTasks: _emberData.default.attr('Number'),
        totalLevel: _emberData.default.attr('Number'),
        mission: _emberData.default.attr({ defaultValue: 1 }),
        level: _emberData.default.attr('Number', { defaultValue: 1 }),
        playerRank: _emberData.default.attr('Number', { defaultValue: 0 }),
        levelTitle: _emberData.default.attr('String'),
        playerHelps: _emberData.default.hasMany('player-help', { async: false })
    });
});