define('excelixis-client/services/app-titles', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        againSubfix: "_againSubfix",
        continueHover: 'Συνέχισε εδώ',
        oneChance: 'Μπορείς να παίξεις μια φορά ακόμα',
        earnPointsDescr1: "Συγχαρητήρια! μόλις κέρδισες ",
        earnPointsDescr2: " πόντους!",
        croppingHeader: "Πώς να περικόψω την εικόνα μου",
        croppingInstructions: "<p>Είναι εύκολο και υπάρχουν πολλοί τρόποι για να το κάνεις! Σύρε προς τα μέσα ή προς τα έξω τις πλευρές του πλαισίου που βλέπεις ή μετακίνησε το πλαίσιο σε όποιο σημείο της εικόνας επιθυμείς. Κάνε αν θες zoom in ή zoom out μέσα σε αυτό με το ποντίκι σου για καλύτερο αποτέλεσμα.</p>"
    });
});