define('excelixis-client/adapters/application', ['exports', 'ember-data', 'excelixis-client/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _environment, _dataAdapterMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
        host: _environment.default.APP.API_HOST,
        authorizer: 'authorizer:token',
        namespace: _environment.default.APP.API_DIR + '/api',
        headers: {
            'Accept': 'application/json'
        },
        pathForType: function pathForType(type) {
            // all api endpoints are in plural lower and non dashed syntax thus
            // pluralization takes place then we camelize to remove dashes and toLowerCase to
            // flat line the string.
            return Ember.String.camelize(Ember.Inflector.inflector.pluralize(type)).toLowerCase();
        },
        handleResponse: function handleResponse(status) /*headers, payload*/{
            if (status === 404) {
                // handle UI error
            }
            return this._super.apply(this, arguments);
        }
    });
});