define('excelixis-client/services/complete', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        completedTaskId: -1,
        gamePoints: 0,
        gameIsActive: false,
        displayImagesLoaded: false,
        imageLoadQueue: 0,
        startImageLoader: function startImageLoader() {
            // console.log('startImageLoader');
            // console.time('stopImageLoader');
            this.set('imageLoadQueue', this.get('imageLoadQueue') + 1);
            this.set('displayImagesLoaded', true);
        },
        stopImageLoader: function stopImageLoader() {
            // console.timeEnd('stopImageLoader');
            this.set('imageLoadQueue', this.get('imageLoadQueue') - 1);
            if (this.get('imageLoadQueue') == 0) {
                this.set('displayImagesLoaded', false);
            }
        }
    });
});